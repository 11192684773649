/*
#############################################################################
# Name : whatsappButton.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display whatsapp button component
#############################################################################
*/
import React, {Component} from 'react';

class WhatsAppButton extends Component {
    render() {
        return (
            <a className="whatsapp_btn" id="whatsappButton" href="https://wa.me/919899622242" target="_blank">
                <img
                    src="/assets/images/icons/whatsapp.png"
                    alt="WhatsApp Button"
                    className="img-fluid"/>
            </a>
        )
    }
}

export default WhatsAppButton;