/*
#############################################################################
# Name : loginWithOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to check the login and verfiy in the mobile OTP or Google or Facebook
#############################################################################
*/
import React, { Component } from 'react';
import Header from '../../components/Header';
import WhatsAppButton from '../../components/whatsappButton';
import ReactFormInputValidation from 'react-form-input-validation';
import { Link, Redirect } from 'react-router-dom';
import {
  getMobileLoginAPI,
  dinerSigninAPI,
  getUserLocationAPI
} from '../../core/services/APIservice';
import { connect } from 'react-redux';
import { saveUserData } from '../../redux/actions/authActions';

// React Notification
import { NotificationManager } from 'react-notifications';
import config from '../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { Helmet } from 'react-helmet';

class LoginWithOTPPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        mobileNumber: ''
      },
      buttonText: 'log in with otp',
      buttonDisabled: false,
      errors: {}
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      mobileNumber: 'required|numeric|digits_between:10,10'
    });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.setState({
        buttonText: 'Sending OTP...',
        buttonDisabled: true
      });
      this.recaptcha.execute();
      localStorage.setItem('userMobile', fields.mobileNumber);
      return getMobileLoginAPI(fields.mobileNumber)
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              buttonText: 'log in with otp',
              buttonDisabled: false
            });
            if (
              this.props.location.search !== '' &&
              this.props.location.search.indexOf('redirect=') !== -1
            ) {
              this.props.history.push(
                '/verfiyMobileOtp' + this.props.location.search
              );
            } else {
              this.props.history.push('/verfiyMobileOtp');
            }
          } else {
            this.setState({
              buttonText: 'log in with otp',
              buttonDisabled: false
            });
            NotificationManager.error(response.data.message, 'Error!');
          }
        })
        .catch((error) => {
          this.setState({
            buttonText: 'log in with otp',
            buttonDisabled: false
          });
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    try {
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('OneSignal_playerId', userId);
      });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log('is subscribed', isSubscribed);
        OneSignal.push(function () {
          console.log('attempt to get id'); // doesn't get this far
          OneSignal.getUserId(function (userId) {
            console.log('user id', userId); // doesn't get this far
            localStorage.setItem('OneSignal_playerId', userId);
          });
        });
      });
    } catch (error) {
      console.error(error);
    }

    this.myInterval = setInterval(this.triggerFacebookLogin, 2000);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  /* responseFacebook function starts here */
  responseFacebook = () => {
    // if (response.email) {
    let email = localStorage.getItem('facebook_user_email');
    if (email && email !== '' && email !== null) {
      return dinerSigninAPI(email, '', 'Facebook')
        .then((response) => {
          if (response.data['status'] === 200) {
            var token = response.data['data']['api_access_token'];
            var userId = response.data['data']['user']['user_id'];
            var userEmail = response.data['data']['user']['email'];
            var userName = response.data['data']['user']['name'];
            var userMobile = response.data['data']['user']['mobile_number'];
            var image = response.data['data']['user']['profile_image'];
            localStorage.setItem('userID', userId);
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('userName', userName);
            localStorage.setItem('userImage', image);
            localStorage.setItem('accessToken', token);
            localStorage.setItem('userMobile', userMobile);

            localStorage.removeItem('facebook_logged_in');
            localStorage.removeItem('facebook_user_email');
            localStorage.removeItem('facebook_user_firstname');
            localStorage.removeItem('facebook_user_lastname');
            localStorage.removeItem('facebook_user_picture');
            localStorage.removeItem('facebook_user_token');

            let data = {
              userType: response.data.is_chef ? 'Chef' : 'Diner',
              userId: response.data['data']['user']['user_id'],
              userEmail: response.data['data']['user']['email'],
              userName: response.data['data']['user']['name'],
              userMobile: response.data['data']['user']['mobile_number'],
              image: response.data['data']['user']['profile_image'],
              verifid: response.data['data']['user']['profile_verified'],
              accessToken: response.data['data']['api_access_token']
            };
            this.props.saveUserData(data);
            if (
              response.data.is_admin == false &&
              response.data.is_chef == true
            ) {
              if (this.props.location.search === '?checkout') {
                return getUserLocationAPI(
                  localStorage.getItem('userID'),
                  localStorage.getItem('accessToken'),
                  localStorage.getItem('latitude'),
                  localStorage.getItem('longitude'),
                  localStorage.getItem('selectedLocationName'),
                  localStorage.getItem('addressSelected'),
                  localStorage.getItem('pincode'),
                  localStorage.getItem('addressType'),
                  localStorage.getItem('addressDetailType'),
                  localStorage.getItem('flatNo'),
                  localStorage.getItem('landmark')
                )
                  .then((response) => {
                    if (response.data['status'] === 200) {
                      NotificationManager.success(
                        response.data['message'],
                        'Successful!',
                        3000
                      );
                      this.props.history.push('/checkout');
                    } else {
                      NotificationManager.error(
                        response.data['message'],
                        'Error!'
                      );
                    }
                  })
                  .catch((error) => {
                    /* console.log(error); */
                  });
              } else {
                this.props.history.push('/user-details');
              }
            } else {
              if (response.data['data']['location'] == null) {
                this.props.history.push('/pick-city');
              } else {
                var address = response.data['data']['location'];
                var addressType = response.data['data']['type'];
                var latitude = response.data['data']['latitude'];
                var longtitude = response.data['data']['longitude'];
                var city = response.data['data']['city'];
                localStorage.setItem('addressSelected', address);
                localStorage.setItem('addressType', addressType);
                localStorage.setItem('latitude', latitude);
                localStorage.setItem('longitude', longtitude);
                localStorage.setItem('selectedLocation', city);
                localStorage.setItem('selectedLocationName', city);
                if (this.props.location.search === '?checkout') {
                  return getUserLocationAPI(
                    localStorage.getItem('userID'),
                    localStorage.getItem('accessToken'),
                    localStorage.getItem('latitude'),
                    localStorage.getItem('longitude'),
                    localStorage.getItem('selectedLocationName'),
                    localStorage.getItem('addressSelected'),
                    localStorage.getItem('pincode'),
                    localStorage.getItem('addressType'),
                    localStorage.getItem('addressDetailType'),
                    localStorage.getItem('flatNo'),
                    localStorage.getItem('landmark')
                  )
                    .then((response) => {
                      if (response.data['status'] === 200) {
                        NotificationManager.success(
                          response.data['message'],
                          'Successful!',
                          3000
                        );
                        this.props.history.push('/checkout');
                      } else {
                        NotificationManager.error(
                          response.data['message'],
                          'Error!'
                        );
                      }
                    })
                    .catch((error) => {
                      /* console.log(error); */
                    });
                } else {
                  var selectedCity = config.slugify(city);
                  this.props.history.push(
                    '/' + selectedCity + '/diner-homepage'
                  );
                }
              }
            }
            NotificationManager.success(
              'Welcome to CHEFPIN ' + userName,
              'Successful!',
              3000
            );
          } else if (response.status === 500) {
            NotificationManager.error(response.data.message, 'Error!');
          } else if (response.data.status === 405) {
            NotificationManager.error(response.data.message, 'Error!');
          } else {
            NotificationManager.error(response.data['message'], 'Error!');
          }
        })
        .catch((error) => {
          /* console.log(error); */
          NotificationManager.error(
            'Something went wrong while Login',
            'Error!'
          );
        });
    } else {
      NotificationManager.error('Use Email Id for Login', 'Error!');
    }
    // }
  };
  /* responseFacebook function ends here */

  /* triggerFacebookLogin function starts here */
  triggerFacebookLogin = () => {
    if (localStorage.getItem('facebook_logged_in') === 'true') {
      this.responseFacebook();
      localStorage.removeItem('facebook_logged_in');
    }
  };
  /* triggerFacebookLogin function ends here */

  /* login function starts here */
  login = (response) => {
    return dinerSigninAPI(response.profileObj['email'], '', 'Google')
      .then((response) => {
        if (response.data['status'] === 200) {
          var token = response.data['data']['api_access_token'];
          var userId = response.data['data']['user']['user_id'];
          var userEmail = response.data['data']['user']['email'];
          var userName = response.data['data']['user']['name'];
          var userMobile = response.data['data']['user']['mobile_number'];
          var image = response.data['data']['user']['profile_image'];
          localStorage.setItem('userID', userId);
          localStorage.setItem('userEmail', userEmail);
          localStorage.setItem('userName', userName);
          localStorage.setItem('userImage', image);
          localStorage.setItem('accessToken', token);
          localStorage.setItem('userMobile', userMobile);
          let data = {
            userType: response.data.is_chef ? 'Chef' : 'Diner',
            userId: response.data['data']['user']['user_id'],
            userEmail: response.data['data']['user']['email'],
            userName: response.data['data']['user']['name'],
            userMobile: response.data['data']['user']['mobile_number'],
            image: response.data['data']['user']['profile_image'],
            verifid: response.data['data']['user']['profile_verified'],
            accessToken: response.data['data']['api_access_token']
          };
          this.props.saveUserData(data);
          if (
            response.data.is_admin == false &&
            response.data.is_chef == true
          ) {
            if (this.props.location.search === '?checkout') {
              return getUserLocationAPI(
                localStorage.getItem('userID'),
                localStorage.getItem('accessToken'),
                localStorage.getItem('latitude'),
                localStorage.getItem('longitude'),
                localStorage.getItem('selectedLocationName'),
                localStorage.getItem('addressSelected'),
                localStorage.getItem('pincode'),
                localStorage.getItem('addressType'),
                localStorage.getItem('addressDetailType'),
                localStorage.getItem('flatNo'),
                localStorage.getItem('landmark')
              )
                .then((response) => {
                  if (response.data['status'] === 200) {
                    NotificationManager.success(
                      response.data['message'],
                      'Successful!',
                      3000
                    );
                    this.props.history.push('/checkout');
                  } else {
                    NotificationManager.error(
                      response.data['message'],
                      'Error!'
                    );
                  }
                })
                .catch((error) => {
                  /*   console.log(error); */
                });
            } else {
              this.props.history.push('/user-details');
            }
          } else {
            if (response.data['data']['location'] == null) {
              this.props.history.push('/pick-city');
            } else {
              var address = response.data['data']['location'];
              var addressType = response.data['data']['type'];
              var latitude = response.data['data']['latitude'];
              var longtitude = response.data['data']['longitude'];
              var city = response.data['data']['city'];
              localStorage.setItem('addressSelected', address);
              localStorage.setItem('addressType', addressType);
              localStorage.setItem('latitude', latitude);
              localStorage.setItem('longitude', longtitude);
              localStorage.setItem('selectedLocation', city);
              localStorage.setItem('selectedLocationName', city);
              if (this.props.location.search === '?checkout') {
                return getUserLocationAPI(
                  localStorage.getItem('userID'),
                  localStorage.getItem('accessToken'),
                  localStorage.getItem('latitude'),
                  localStorage.getItem('longitude'),
                  localStorage.getItem('selectedLocationName'),
                  localStorage.getItem('addressSelected'),
                  localStorage.getItem('pincode'),
                  localStorage.getItem('addressType'),
                  localStorage.getItem('addressDetailType'),
                  localStorage.getItem('flatNo'),
                  localStorage.getItem('landmark')
                )
                  .then((response) => {
                    if (response.data['status'] === 200) {
                      NotificationManager.success(
                        response.data['message'],
                        'Successful!',
                        3000
                      );
                      this.props.history.push('/checkout');
                    } else {
                      NotificationManager.error(
                        response.data['message'],
                        'Error!'
                      );
                    }
                  })
                  .catch((error) => {
                    /*  console.log(error); */
                  });
              } else {
                var selectedCity = config.slugify(city);
                this.props.history.push('/' + selectedCity + '/diner-homepage');
              }
            }
          }
          NotificationManager.success(
            'Welcome to CHEFPIN ' + userName,
            'Successful!',
            3000
          );
        } else if (response.status === 500) {
          NotificationManager.error(response.data.message, 'Error!');
        } else if (response.data.status === 405) {
          NotificationManager.error(response.data.message, 'Error!');
        } else {
          NotificationManager.error(response.data['message'], 'Error!');
        }
      })
      .catch((error) => {
        NotificationManager.error('Something went wrong while Login', 'Error!');
      });
  };
  /* login function ends here */

  /* handleLoginFailure function starts here */
  handleLoginFailure(response) {}
  /* handleLoginFailure function ends here */

  /* handleLogoutFailure function starts here */
  handleLogoutFailure(response) {}
  /* handleLogoutFailure function starts here */

  /* goToPasswordLogin function starts here */
  goToPasswordLogin = () => {
    if (
      this.props.location.search !== '' &&
      this.props.location.search.indexOf('redirect=') !== -1
    ) {
      this.props.history.push(
        '/signin-with-password' + this.props.location.search
      );
    } else {
      this.props.history.push('/signin-with-password');
    }
  };
  /* goToPasswordLogin function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    if (localStorage.getItem('accessToken') !== null) {
      return <Redirect to='/user-details' />;
    }
    return (
      <React.Fragment>
        <div>
          <Helmet>
            <title>Join us as a Home Chef | Chef Pin</title>
            <meta name='title' content='Join us as a Home Chef | Chef Pin.' />
            <meta
              name='description'
              content='Excellent at cooking food, then express your talent and start earning. Sign Up & be the part of India’s leading home chef portal. Register Now on Chef Pin.'
            />
            <meta
              name='keywords'
              content='Register home chef, Join Home Chef, Join Home Chef online, home chefs near me, home chefs online, home chefs online Platform'
            />
          </Helmet>
          <Header />
          <section className='sign_up_chef diner_sign_up verification log_otp'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 d-flex d-xl-block align-items-center justify-content-center justify-content-lg-start'>
                  <div className='text'>
                    <h1 className='color_C07'>LOG IN TO CHEF PIN</h1>
                    <div className='profile_img'>
                      <div className='inner'>
                        <img
                          src='/assets/images/icons/profile-img.png'
                          alt='Profile Images'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='form_wrap'>
                      <form onSubmit={this.form.handleSubmit}>
                        <div className='form-group'>
                          <div className='mobile_no_wrap'>
                            <div className='form-control countrycode'>
                              <span className='code'>+91</span>
                            </div>
                            <input
                              type='tel'
                              className='form-control tel_number'
                              id='Phone'
                              placeholder='Mobile Number*'
                              name='mobileNumber'
                              onBlur={this.form.handleBlurEvent}
                              onChange={this.form.handleChangeEvent}
                              value={this.state.fields.mobileNumber}
                              maxLength='10'
                            />
                          </div>
                          <small className='form-text red_000'>
                            {this.state.errors.mobileNumber
                              ? 'Mobile Number is Required'
                              : ''}
                          </small>
                          <small
                            id='PasswordHelp'
                            className='form-text text-muted'
                          >
                            Enter your 10 digit mobile number.
                          </small>
                        </div>
                        <div className='form-group mb-0'>
                          <button
                            type='submit'
                            disabled={this.state.buttonDisabled}
                            className='btn'
                          >
                            {this.state.buttonText}
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                    <p className='resend_otp_text log_using_psd color_036 text-center text-sm-left mb-0'>
                      or Log in using
                      <a
                        href='#'
                        onClick={this.goToPasswordLogin}
                        className='color_C07'
                      >
                        {' '}
                        Password
                      </a>
                    </p>

                    <div className='social_center mt-3'>
                      <span className='or_text ml-0'>or Continue with</span>
                      <ul className='social_icon'>
                        <li>
                          <GoogleLogin
                            clientId={config.CLIENT_ID}
                            buttonText=''
                            onSuccess={this.login}
                            onFailure={this.handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                            responseType='code,token'
                            className='img-fluid'
                          />
                        </li>
                        <li>
                          {/* <FacebookLogin
                                                        appId={config.APP_ID}
                                                        fields="name,email,picture"
                                                        callback={this.responseFacebook}
                                                        icon="fa-facebook"
                                                        textButton=""
                                                        disableMobileRedirect={true}
                                                    /> */}
                          <button className='fb-login-custom-button'>
                            <i className='fa fa-facebook'></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-xl-7 align-items-center d-none d-lg-flex'>
                  <div className='img_box'>
                    <img
                      src='/assets/images/sign-up-as-a-chef/sign-up-img.png'
                      alt='sign up img'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='nofooter'>
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items
});

export default connect(mapStateToProps, { saveUserData })(LoginWithOTPPage);
