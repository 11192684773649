/*
#############################################################################
# Name : verifyMobileOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to verfiy the mobile OTP for login verification
#############################################################################
*/
import React, { Component } from "react";
import Header from "../../components/Header";
import WhatsAppButton from "../../components/whatsappButton";
import OtpInput from "react-otp-input";
import {
  getMobileLoginAPI,
  getMobileLoginVerfiyAPI,
  getUserSummaryAPI,
} from "../../core/services/APIservice";
// React Notification
import { NotificationManager } from "react-notifications";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveUserData } from "../../redux/actions/authActions";

class verfiyMobileOTPPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      userMobileNo: "",
      minutes: 1,
      seconds: 0,
      buttonText: "verify otp",
      buttonDisabled: false,
      resendText: "re-send OTP",
      resendDisabled: false,
    };
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var mobileNo = localStorage.getItem("userMobile");
    this.setState({ userMobileNo: mobileNo });
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;
      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  /* resendOTP function starts here */
  resendOTP = () => {
    this.setState({
      otp: "",
      resendText: "re-sending OTP...",
      resendDisabled: true,
    });
    return getMobileLoginAPI(this.state.userMobileNo)
      .then((response) => {
        this.setState({
          resendText: "re-send OTP",
          resendDisabled: false,
        });
        this.setState({
          minutes: 1,
          seconds: 0,
        });
        this.myInterval = setInterval(() => {
          const { seconds, minutes } = this.state;
          if (seconds > 0) {
            this.setState(({ seconds }) => ({
              seconds: seconds - 1,
            }));
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(this.myInterval);
            } else {
              this.setState(({ minutes }) => ({
                minutes: minutes - 1,
                seconds: 59,
              }));
            }
          }
        }, 1000);
        NotificationManager.success("OTP Sent", "Success!");
      })
      .catch((error) => {
        this.setState({
          resendText: "re-send OTP",
          resendDisabled: false,
        });
      });
  };
/* resendOTP function ends here */

/* clearotp function starts here */
  clearotp = (event) => {
    event.preventDefault();
    this.setState({ otp: "" });
  };
/* clearotp function ends here */

/* handleChange function starts here */
  handleChange = (otp) => {
    this.setState({ otp });
  };
/* handleChange function ends here */

/* verfiyOTP function starts here */
  verfiyOTP = (event) => {
    event.preventDefault();
    this.setState({
      buttonText: "Verifying OTP...",
      buttonDisabled: true,
    });
    this.recaptcha.execute();
    return getMobileLoginVerfiyAPI(this.state.userMobileNo, this.state.otp)
      .then((response) => {
        if (response.data.status === 200) {
          this.setState({
            buttonText: "verify otp",
            buttonDisabled: false,
          });
          var token = response.data["data"]["api_access_token"];
          var userId = response.data["data"]["user"]["user_id"];
          var userEmail = response.data["data"]["user"]["email"];
          var userName = response.data["data"]["user"]["name"];
          var userMobile = response.data["data"]["user"]["mobile_number"];
          var image = response.data["data"]["user"]["profile_image"];
          var verified = response.data["data"]["profile_verified"];
          var membership = response.data.membership;
          var admin = response.data.is_admin;
          var chef = response.data.is_chef;
          localStorage.setItem("userID", userId);
          localStorage.setItem("userEmail", userEmail);
          localStorage.setItem("userName", userName);
          localStorage.setItem("userImage", image);
          localStorage.setItem("accessToken", token);
          localStorage.setItem("userMobile", userMobile);
          localStorage.setItem("userVerified", verified);
          localStorage.setItem("userMemberShip", membership);

          let data = {
            userType: response.data.is_chef ? "Chef" : "Diner",
            userId: response.data["data"]["user"]["user_id"],
            userEmail: response.data["data"]["user"]["email"],
            userName: response.data["data"]["user"]["name"],
            userMobile:
              response.data["data"]["user"]["mobile_number"],
            image: response.data["data"]["user"]["profile_image"],
            verifid:
              response.data["data"]["user"]["profile_verified"],
            accessToken: response.data["data"]["api_access_token"],
            isAvailable: response.data.is_available,
            auditMe: response.data.a_m_a_enabled,
            membership: response.data.membership
              ? response.data.membership
              : "",
          };
          this.props.saveUserData(data);
          /* if (response.data["data"]["profile_verified"] === "0") {
            this.props.history.push("/user-details");
          } else */ if (
            response.data["data"]["profile_verified"] == 1
          ) {
            if (response.data.membership == "basic") {
              this.props.history.push("/chef-membership");
            } else {
              if (this.props.location.search !== "" && this.props.location.search.indexOf("redirect=") !== -1) {
                this
                  .props
                  .history
                  .push('/' + this.props.location.search.split("=")[1]);
              } else {
                this
                  .props
                  .history
                  .push('/chef-dashboard');
              }
            }
          } else {
            if (admin == false && chef == true) {
              var token = response.data["data"]["api_access_token"];
              var userId = response.data["data"]["user"]["user_id"];
              var userEmail = response.data["data"]["user"]["email"];
              var userName = response.data["data"]["user"]["name"];
              var userMobile = response.data["data"]["user"]["mobile_number"];
              var image = response.data["data"]["user"]["profile_image"];
              return getUserSummaryAPI(userId, token)
                .then((response) => {
                  if (response.data["status"] === 200) {
                    this.setState({
                      userAboutYou: response.data["data"]["about_average"],
                      userBusiness: response.data["data"]["business_average"],
                      userKitchen: response.data["data"]["kitchen_average"],
                      userMenu:
                        response.data["data"]["number_of_uploaded_menu"],
                    });
                    var setStatus = false;

                    if (
                      response.data["data"]["about_average"] < 100 &&
                      setStatus === false
                    ) {
                      localStorage.setItem("currentStatus", 1);
                      setStatus = true;
                    } else if (
                      response.data["data"]["business_average"] < 100 &&
                      setStatus === false
                    ) {
                      localStorage.setItem("currentStatus", 2);
                      setStatus = true;
                    } else if (
                      response.data["data"]["kitchen_average"] < 100 &&
                      setStatus === false
                    ) {
                      localStorage.setItem("currentStatus", 3);
                      setStatus = true;
                    } else {
                      localStorage.setItem("currentStatus", 4);
                    }
                    localStorage.setItem("userID", userId);
                    localStorage.setItem("userEmail", userEmail);
                    localStorage.setItem("userName", userName);
                    localStorage.setItem("userImage", image);
                    localStorage.setItem("accessToken", token);
                    localStorage.setItem("userMobile", userMobile);
                    let data = {
                      userType: response.data.is_chef ? "Chef" : "Diner",
                      userId: response.data["data"]["user"]["user_id"],
                      userEmail: response.data["data"]["user"]["email"],
                      userName: response.data["data"]["user"]["name"],
                      userMobile:
                        response.data["data"]["user"]["mobile_number"],
                      image: response.data["data"]["user"]["profile_image"],
                      verifid:
                        response.data["data"]["user"]["profile_verified"],
                      accessToken: response.data["data"]["api_access_token"],
                      isAvailable: response.data.is_available,
                      auditMe: response.data.a_m_a_enabled,
                      membership: response.data.membership
                        ? response.data.membership
                        : "",
                    };
                    this.props.saveUserData(data);
                    this.props.history.push("/user-details");
                  } else {
                    NotificationManager.error(
                      response.data["message"],
                      "Error!"
                    );
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (admin == true && chef == false) {
              NotificationManager.error(
                "You are trying login with Admin Credentials",
                "Error!"
              );
            } else {
              if (response.data["data"]["location"] == null) {
                this.props.history.push("/pick-city");
              } else {
                var address = response.data["data"]["location"];
                var addressType = response.data["data"]["type"];
                var latitude = response.data["data"]["latitude"];
                var longtitude = response.data["data"]["longitude"];
                var city = response.data["data"]["city"];
                localStorage.setItem("addressSelected", address);
                localStorage.setItem("addressType", addressType);
                localStorage.setItem("latitude", latitude);
                localStorage.setItem("longitude", longtitude);
                localStorage.setItem("selectedLocation", city);
                localStorage.setItem("selectedLocationName", city);
                var selectedCity = config.slugify(city);
                this.props.history.push("/" + selectedCity + "/diner-homepage");
              }
            }
            NotificationManager.success(
              "Welcome back to CHEFPIN " + userName,
              "Successful!",
              3000
            );
          }
        } else {
          this.setState({ otp: "" });
          this.setState({
            buttonText: "verify otp",
            buttonDisabled: false,
          });
          this.recaptcha.reset();
          NotificationManager.error(response.data["message"], "Error!");
        }
      })
      .catch((error) => {
        this.setState({
          buttonText: "verify otp",
          buttonDisabled: false,
        });
        this.recaptcha.reset();
        NotificationManager.error("Wrong OTP", "Error!");
      });
  };
/* verfiyOTP function ends here */

/* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
/* onResolved function ends here */

  render() {
    if (localStorage.getItem("accessToken") !== null) {
      return <Redirect to="/user-details" />;
    }
    const { minutes, seconds } = this.state;
    return (
      <div>
        <Header />
        <section className="sign_up_chef verification">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-xl-5 d-flex d-xl-block align-items-center justify-content-center justify-content-lg-start">
                <div className="text">
                  <h3 className="color_C07">verification</h3>
                  <div className="profile_img">
                    <div className="inner">
                      <img
                        src="assets/images/icons/verification.png"
                        alt="Verification Images"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                  <h5>Verify with OTP</h5>
                  <p className="opt_text">Sent to {this.state.userMobileNo}</p>
                  <div className="form_wrap">
                    <form>
                      <div className="form-group">
                        <label className="otp_label">
                          Enter the 6 digit OTP
                        </label>
                        <OtpInput
                          containerStyle={"otp_input_wrap"}
                          inputStyle={"form-control"}
                          value={this.state.otp}
                          onChange={this.handleChange}
                          numInputs={6}
                          shouldAutoFocus
                          isInputNum={true}
                          separator={false}
                        />
                      </div>
                    </form>
                  </div>
                  <p className="resend_otp_text color_C07 mb-0">
                    OTP will expire in
                    <span className="color_036 ml-2">
                      {minutes > 9 ? minutes : "0" + minutes}:
                      {seconds > 9 ? seconds : "0" + seconds}
                    </span>
                  </p>
                  <div className="btn-group opt_btn_grp">
                    <button
                      className="btn"
                      disabled={
                        this.state.otp.toString().length < 6 ||
                        this.state.buttonDisabled
                      }
                      onClick={this.verfiyOTP}
                    >
                      {this.state.buttonText}
                    </button>
                    <button
                      className="btn previous_btn"
                      onClick={this.resendOTP}
                      disabled={
                        minutes > 0 || seconds > 0 || this.state.resendDisabled
                      }
                    >
                      {this.state.resendText}
                    </button>
                  </div>
                  <Recaptcha
                    ref={(ref) => (this.recaptcha = ref)}
                    sitekey={config.siteKey}
                    onResolved={this.onResolved}
                  />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-7 align-items-center d-none d-md-flex">
                <div className="img_box">
                  <img
                    src="assets/images/sign-up-as-a-chef/sign-up-img-verification.png"
                    alt="sign up img verification"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items,
});

export default connect(mapStateToProps, { saveUserData })(verfiyMobileOTPPage);
