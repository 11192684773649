/*
#############################################################################
# Name : dinerFooter.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to display 404 Error thorugh out the side
#############################################################################
*/

import React from 'react';
import { Link } from 'react-router-dom';
import config from '../config/config';

class NotFound extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selectedCity: ""
        }
    }

    componentDidMount(){
        if(localStorage.getItem("selectedLocation")){
            var selectedCity = config.slugify(localStorage.getItem("selectedLocation"));
            this.setState({
              selectedCity: "/"+selectedCity+"/diner-homepage"
            })
        }
    }

    render(){
        return(
            <div className="css2">
                <div className="menu_fixed_pad"></div>
                <section className="not_found empty_section d-flex justify-content-center align-content-center flex-column h-100">
                    <div className="container text-center">
                        <img src="/assets/images/icons/404.svg" alt="Not Found" className="img-fluid" />
                        <h6>OOPS! THE PAGE YOU WERE LOOKING <br/>FOR DOES NOT EXIST.</h6>
                        <Link to={localStorage.getItem('selectedLocation') ? this.state.selectedCity: "/"} className="btn">GO BACK TO HOMEpage</Link>
                    </div>
                </section>
            </div>
        )
    }
} 

export default NotFound;