import { URLs } from "../Url";
export const handlePostImage = async (updateimage) => {
  try {
    var formdata = new FormData();
    formdata.append("image", updateimage);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/home-imageupload",
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const handledeleteImage = async (name) => {
  try {
    var requestOptions = {
      method: "DELETE",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/home-deleteimage/" + name,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};

//pages
export const GetPages = async () => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(URLs.domain + "/api/pages", requestOptions);
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const GetPageDetail = async (slug) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/page-detail/" + slug,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const DeletePages = async (id) => {
  try {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    console.log("json");
    const response = await fetch(
      URLs.domain + "/api/delete-page/" + id,
      requestOptions
    );
    const json = await response.json();
    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Metatagsupload = async (
  name,
  slug,
  title,
  description,
  status,
  finalImage,
  chefs,
  viewAll,
  // metatitle,
  // metadescription,
  // metakeyword,
  // metaogtitle,
  // metaogdescription,
  // finalImage2
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      slug: slug,
      title: title,
      description: description,
      image: finalImage,
      status: status,
      chefs: chefs,
      viewAll: viewAll,

      // metatitle: metatitle,
      // metadescription: metadescription,
      // metakeywords: metakeyword,
      // metaogtitle: metaogtitle,
      // metaogdescription: metaogdescription,
      // metaogimage: finalImage2,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/page-upload",
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
export const Metatagsupdate = async (
  id,
  name,
  slug,
  title,
  description,
  status,
  finalImage,
  chefs,
  viewAll,
  // metatitle,
  // metadescription,
  // metakeyword,
  // metaogtitle,
  // metaogdescription,
  // finalImage2
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      name: name,
      slug: slug,
      title: title,
      description: description,
      image: finalImage,
      status: status,
      chefs: chefs,
      viewAll: viewAll,

      // metatitle: metatitle,
      // metadescription: metadescription,
      // metakeywords: metakeyword,
      // metaogtitle: metaogtitle,
      // metaogdescription: metaogdescription,
      // metaogimage: finalImage2,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const response = await fetch(
      URLs.domain + "/api/page-update/" + id,
      requestOptions
    );
    const json = await response.json();
    console.log(json.data);

    return json;
  } catch (err) {
    console.log(err);
  }
};
