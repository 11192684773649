import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
    BlogDetailData,
    BlogData,
    BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TumblrShareButton,
    TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class NewBlogDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText(html)
            ),
        };
        this.state = {
            isLoading: true,
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            articleTag: [],
            articleTitle: "",
            author: "",
            authorImage: "",
            articleContent: "",
            catgory: "",
            time: "",
            categorydata: [],
            data: [],
        };
    }
    // 63ad512db0441bf02241a2c2


    getblogcatdata = () => {
        BlogCategoryData().then((response) => {
            if (response.success == true) {
                this.setState({
                    categorydata: response.data,
                });
            }
        });
    };
    render() {
        // console.log(window.location.href);
        return (
            <div className="css2">
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li>
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <div className="container pt-5">
                    <div className="row align-self">
                        <div className="col-md-12 m-0 p-0">
                            <div className="container">
                                <p className="text-center color_a05">Latest Updates

                                </p>
                                <span className="text-center mt-2">
                                    <h1 className="color_a05">How local home-based cooks can work with the Chef Pin App</h1>
                                </span>
                                <div className="d-flex align-items-center justify-content-center row m-0 p-0">
                                    <span>POSTED ON</span>
                                    <span className="text-center color_a05">
                                        &nbsp;
                                        JAN 9, 2023
                                    </span>
                                    &nbsp;
                                    <span>BY</span> &nbsp;
                                    <span className="text-center color_a05">
                                        AMEETA AGNIHOTRI

                                    </span>
                                </div>

                                {/* <Editor
                                        toolbarHidden
                                        editorState={this.state.editorState}
                                        readOnly
                                    /> */}
                                <br></br>

                                <h2> Working as a home cook? Want to increase your business? </h2>
                                <p>Then you just need to check out this app!</p>
                                <p>Chef Pin App is a mobile application that gives you a platform that brings home chefs and consumers closer. What makes Chef Pin special is its core team: a group of passionate people who understand the business of food.</p>
                                <p>Just register upload your menu and get ready to give your passion for cooking a whole new direction. All you have to do is sign up, answer a few questions about your skills and interests, and then go about scheduling yourself to work. Register yourself now.
                                    The Chef Pin app will help you in showcasing your menu which will be available to consumers. It lets consumers pre-book slots a minimum day in advance you simply cook according to the order and it will be get delivered to the end-users. The app is easy to use and available for both major platforms- Android & IOS.</p>



                                <hr />
                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Sourdough Central</h3>
                                                        <h6>by Sourdough Central</h6>
                                                    </span>
                                                    {/* <div class="fssai_logo">
                                                        <img
                                                            src="/assets/images/icons/fssai.svg"
                                                            alt="Fssai"
                                                        />
                                                        <span>License No : 10013051000642</span>
                                                        <span class="social_share d-none d-lg-block">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                        <span class="social_share d-block d-lg-none">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                    </div> */}
                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>
                                                        {/* <li
                                                            class="icons pins_achieved"
                                                            data-toggle="modal"
                                                            data-target="#pins_achieved"
                                                        >
                                                            <img
                                                                src="/assets/images/icons/pins-achieved.svg"
                                                                alt=""
                                                            />
                                                            <span>Pins achieved</span>
                                                            <img
                                                                src="/assets/images/icons/pins-achieved-right-arrow.svg"
                                                                alt=""
                                                                class="right_icon"
                                                            />
                                                        </li>
                                                        <li class="icons bookmark">
                                                            <img
                                                                src="/assets/images/icons/bookmark-empty.svg"
                                                                alt=""
                                                            />
                                                            Bookmark
                                                        </li> */}
                                                    </ul>
                                                    <h6>Bakery And Confectionery</h6>
                                                    <p>
                                                        Sourdough Central is known for cooking the best
                                                        Seeds And Beet, Cinnamon And Currant, Pesto
                                                        Sourdough, Cheese And Truffle, Cheese Onion
                                                        Garlic, Cheese Jalapeno, Olive Italian Herb,
                                                        Multigrain Sourdough, Country Sourdough, Classic
                                                        etc.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh ev...
                                                        <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            Read More
                                                        </span>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>
                                                    <div class="logo_icon">
                                                        <img
                                                            src="https://api.chefp.in/uploads/brand-logo/1636884255_918cc4e7cde9129b21af.jpg"
                                                            alt="logo-slider"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Sourdough Central</h3>
                                                        <h6>by Sourdough Central</h6>
                                                    </span>
                                                    {/* <div class="fssai_logo">
                                                        <img
                                                            src="/assets/images/icons/fssai.svg"
                                                            alt="Fssai"
                                                        />
                                                        <span>License No : 10013051000642</span>
                                                        <span class="social_share d-none d-lg-block">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                        <span class="social_share d-block d-lg-none">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                    </div> */}
                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>
                                                        {/* <li
                                                            class="icons pins_achieved"
                                                            data-toggle="modal"
                                                            data-target="#pins_achieved"
                                                        >
                                                            <img
                                                                src="/assets/images/icons/pins-achieved.svg"
                                                                alt=""
                                                            />
                                                            <span>Pins achieved</span>
                                                            <img
                                                                src="/assets/images/icons/pins-achieved-right-arrow.svg"
                                                                alt=""
                                                                class="right_icon"
                                                            />
                                                        </li>
                                                        <li class="icons bookmark">
                                                            <img
                                                                src="/assets/images/icons/bookmark-empty.svg"
                                                                alt=""
                                                            />
                                                            Bookmark
                                                        </li> */}
                                                    </ul>
                                                    <h6>Bakery And Confectionery</h6>
                                                    <p>
                                                        Sourdough Central is known for cooking the best
                                                        Seeds And Beet, Cinnamon And Currant, Pesto
                                                        Sourdough, Cheese And Truffle, Cheese Onion
                                                        Garlic, Cheese Jalapeno, Olive Italian Herb,
                                                        Multigrain Sourdough, Country Sourdough, Classic
                                                        etc.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh ev...
                                                        <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            Read More
                                                        </span>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="https://api.chefp.in/uploads/profile-images/1649488179_0b9e875399ed3a35e145.jpg"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>
                                                    <div class="logo_icon">
                                                        <img
                                                            src="https://api.chefp.in/uploads/brand-logo/1636884255_918cc4e7cde9129b21af.jpg"
                                                            alt="logo-slider"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>

                                <div className="css2">
                                    <section className="chef_inner">
                                        <div className="row">
                                            <div className="col-6">
                                                <div class="text_wrap">
                                                    <span>
                                                        <h3>Sourdough Central</h3>
                                                        <h6>by Sourdough Central</h6>
                                                    </span>
                                                    {/* <div class="fssai_logo">
                                                        <img
                                                            src="/assets/images/icons/fssai.svg"
                                                            alt="Fssai"
                                                        />
                                                        <span>License No : 10013051000642</span>
                                                        <span class="social_share d-none d-lg-block">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                        <span class="social_share d-block d-lg-none">
                                                            <img src="/assets/images/icons/share.svg" />
                                                        </span>
                                                    </div> */}
                                                    <ul class="icon_list">
                                                        <li class="icons start">
                                                            <img
                                                                src="/assets/images/icons/star.svg"
                                                                alt=""
                                                            />
                                                            4.9<span class="grey">/5</span>
                                                        </li>
                                                        {/* <li
                                                            class="icons pins_achieved"
                                                            data-toggle="modal"
                                                            data-target="#pins_achieved"
                                                        >
                                                            <img
                                                                src="/assets/images/icons/pins-achieved.svg"
                                                                alt=""
                                                            />
                                                            <span>Pins achieved</span>
                                                            <img
                                                                src="/assets/images/icons/pins-achieved-right-arrow.svg"
                                                                alt=""
                                                                class="right_icon"
                                                            />
                                                        </li>
                                                        <li class="icons bookmark">
                                                            <img
                                                                src="/assets/images/icons/bookmark-empty.svg"
                                                                alt=""
                                                            />
                                                            Bookmark
                                                        </li> */}
                                                    </ul>
                                                    <h6>Bakery And Confectionery</h6>
                                                    <p>
                                                        Sourdough Central is known for cooking the best
                                                        Seeds And Beet, Cinnamon And Currant, Pesto
                                                        Sourdough, Cheese And Truffle, Cheese Onion
                                                        Garlic, Cheese Jalapeno, Olive Italian Herb,
                                                        Multigrain Sourdough, Country Sourdough, Classic
                                                        etc.
                                                    </p>
                                                    <div class="border_line"></div>
                                                    <p style={{ display: "block" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh ev...
                                                        <span
                                                            class="orange_read"
                                                            style={{ display: "block" }}
                                                        >
                                                            Read More
                                                        </span>
                                                    </p>
                                                    <p style={{ display: "none" }}>
                                                        We have developed a range of Artisan Sourdough
                                                        breads which are made without any commercial
                                                        yeast, additives or preservatives. Breads are
                                                        baked fresh every night and supplied freshly
                                                        across Delhi NCR
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 d-flex justify-content-end pad_0">
                                                <div class="slider_wrap">
                                                    <div class="res_back_btn_wrap">
                                                        <button class="res_back_btn">
                                                            <img
                                                                src="/assets/images/icons/white-left-arrow.png"
                                                                alt="left-arrow-black"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div class="img_wrap">
                                                        <img
                                                            src="https://api.chefp.in/uploads/profile-images/1697207219_000021f6a1114c9a0106.jpg"
                                                            alt=""
                                                            class="img-fluid"
                                                        />
                                                    </div>
                                                    <div class="logo_icon">
                                                        <img
                                                            src="https://api.chefp.in/uploads/brand-logo/1696486613_e5be38be222722fe9650.jpg"
                                                            alt="logo-slider"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="border_bottom mb-5"></div>
                                </div>


                            </div>
                        </div>

                    </div>
                </div>

                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>

                <DinerMenuTab />
                <DinerFooter />
            </div>
        );
    }
}

export default NewBlogDetails;
