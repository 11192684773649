import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PartyOrderForm from "./PartyOrderForm";
import DinerFooter from "../../components/dinerFooter";
import Slider from "react-slick";
import { HomeDescription } from "../../backendUI/Home/config";
import config from "../../config/config";

class BlogDetailStatic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      HeadTitle: "",
    };
  }

  componentDidMount() {
    if (!localStorage.getItem("closePopup")) {
      this.setState({
        displayPopup: true,
      });
    } else {
      this.setState({
        displayPopup: false,
      });
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    if (localStorage.getItem("selectedLocation")) {
      var selectedCity = config.slugify(
        localStorage.getItem("selectedLocation")
      );
      this.setState({
        selectedCity: "/" + selectedCity + "/diner-homepage",
      });
    }

    this.getHeadTitle();
  }

  getHeadTitle = () => {
    HomeDescription().then((res) => {
      if (res.success == true) {
        this.setState({
          HeadTitle: res.data[0].description,
        });
      }
    });
  };

  render() {
    return (
      <div className="css2">
        <header>
          <div className="container menu_wrapper">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
              </a>
            </div>
            <div id="nav-icon" className=" d-block d-xl-none">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="dinner_menu">
              <li className="active">
                {" "}
                <a href="/" className="login_btn">
                  Home
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/diner-aboutus" className="login_btn">
                  About Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a
                  href="https://wa.me/919899622242"
                  target="_blank"
                  className="login_btn"
                >
                  Contact Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/pick-city" className="login_btn">
                  Pick A City
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/blog" className="login_btn">
                  Blog
                </a>{" "}
              </li>
              <li className="d-block d-xl-none last_btn">
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <ul className="dinner_menu d-none d-xl-block">
              <li>
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <div className="menu_overlay"></div>
          </div>
        </header>

        <section className="banner-media-media">
          <h4>Top Chefs of Noida</h4>
        </section>

        <section>
          <div className="container pt-5">
            <div className="row align-self justify-content-center my-5">
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
            </div>
            <div className="row align-self justify-content-center my-5">
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
            </div>
            <div className="row align-self justify-content-center my-5">
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
              <div className="col-md-2 sm-12 lg-2 mx-4 p-0 text-center">
                <img
                  src="https://api.chefp.in/uploads/profile-images/1636884255_c48c3478306ec2682262.jpg"
                  alt=""
                  class="img-fluid rounded shadow mb-4"
                />
                <h4>Sourdough Central</h4>
                <span>Sourdough Central</span>
              </div>
            </div>
          </div>
        </section>

        <DinerFooter />
      </div>
    );
  }
}

export default BlogDetailStatic;
