/*
#############################################################################
# Name : App.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to define routes and all the packages of Chefpin
#############################################################################
*/
import React, { useEffect, useState, useWindowDimensions } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import history from "./history";
import LandingPage from "./screens/landing/landing";
import SignupPage from "./screens/chefSignup/signup";
import UserSelectionPage from "./screens/diner/userSelection";
import AboutUsChefPage from "./screens/landing/aboutusChef";
import AboutUsDinerPage from "./screens/diner/aboutusDiner";
import VerifyOTPPage from "./screens/chefSignup/verifyOTP";
import verfiyMobileOTPPage from "../src/screens/chefSignIn/verifyMobileOTP";
import LoginWithPasswordPage from "./screens/chefSignIn/loginWithPassword";
import LoginWithOTPPage from "./screens/chefSignIn/loginWithOTP";
import FetchLocationPage from "./screens/pickcity/fetchLocation";
import TermsPage from "./screens/landing/terms";
import PolicyPage from "./screens/landing/policy";
import FAQPage from "./screens/landing/faq";
import ForgotPasswordPage from "./screens/forgotPassword/forgotPassword";
import PickCityPage from "./screens/pickcity/pickCity";
import DinerHomepage from "./screens/diner/dinerHomepage";
import PartyOrderScreen from "./screens/diner/PartyOrderPage";
import Maintainance from "./screens/Maintainance";
import MediaEventsPage from "./screens/diner/MediaEventsPage";
import NewBlogDetails from "./screens/diner/NewBlogDetails";

import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import BlogsScreen from "./screens/diner/BlogsScreen";
import BlogDetail from "./screens/diner/BlogDetails";
// React Backend crm

import DashboardSideBar from "../src/backendUI/DashboardSideBar";
import { URLs } from "./backendUI/Url";
// import DashLogin from "./backendUI/DashLogin";
// React Notification
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import NotFound from "../src/screens/404";
import ChefAlertPage from "./screens/chefOrder/chefAlerts";
import ReactPixel from "react-facebook-pixel";
import DeepLinking from "./screens/deep-linking";
import ArticleData from "./screens/diner/articleData";
import { isAndroid, isMobile } from "react-device-detect";
import ThankYouPage from "./screens/landing/ThankYouPage";
import BlogDetailStatic from "./screens/diner/BlogDetailStatic";
import NewBlogPage1 from "./screens/diner/NewBlogPage1";
import NewBlogPage2 from "./screens/diner/NewBlogPage2";
import NewBlogPage3 from "./screens/diner/NewBlogPage3";
import TiffinServiceScreen from "./screens/diner/TiffinServices";
import TiffinThankYouPage from "./screens/landing/TiffinThankYouPage";
import PartyThankYouPage from "./screens/landing/PartyThankYouPage";
import HomeChefsNearMe from "./screens/diner/homeChefsNearMe";
import onlineHomemadeFood from "./screens/diner/onlineHomemadeFood";
import HomeCookedMeal from "./screens/diner/HomeCookedMeal";
import CookedApp from "./screens/diner/CookedApp";
import HomeChefs from "./screens/diner/HomeChefs";
import CooksApp from "./screens/diner/CooksApp";
import HomeCookedFood from "./screens/diner/HomeCookedFood";
import FoodChefs from "./screens/diner/FoodChefs";
import ChefFood from "./screens/diner/ChefFood";
// import GA4React, { useGA4React } from "ga-4-react";
const persistor = persistStore(store);

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

const App = () => {
  const [redirect, setRedirect] = useState(true);

  window.OneSignal = window.OneSignal || [];
  const OneSignal = window.OneSignal;
  useEffect(() => {
    OneSignal.push(() => {
      OneSignal.init({
        // appId: "858ead9d-4613-4828-8e83-ce4e26539af4",     Arvind ID
        appId: "6ca82d47-a584-42fe-b8f5-9549a77cf19b",
        notifyButton: {
          enable: true,
        },
      });
      OneSignal.showNativePrompt();
      try {
        OneSignal.getUserId().then(function (userId) {
          console.log("OneSignal User ID:", userId);
          localStorage.setItem("OneSignal_playerId", userId);
        });
        OneSignal.on("subscriptionChange", function (isSubscribed) {
          console.log("is subscribed", isSubscribed);
          OneSignal.push(function () {
            console.log("attempt to get id"); // doesn't get this far
            OneSignal.getUserId(function (userId) {
              console.log("user id", userId); // doesn't get this far
            });
          });
        });
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  useEffect(() => {
    ReactPixel.init("344072553470177", options);
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    setRedirect(isAndroid);
  }, []);

  const checkauthentication = localStorage.getItem("authentication");
  console.log(checkauthentication);
  const fakeAuth = {
    isAuthenticated: checkauthentication == "true" ? true : false,
    authenticate(cb) {
      fakeAuth.isAuthenticated = true;
      setTimeout(cb, 100); // fake async
    },
    unauthenticate(cb) {
      fakeAuth.isAuthenticated = false;
      setTimeout(cb, 100);
    },
  };
  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return fakeAuth.isAuthenticated === true ? (
            children
          ) : (
            <Redirect
              to={{ pathname: "/DashLogin", state: { from: location } }}
            />
          );
        }}
      />
    );
  }
  const DashLogin = () => {
    const [size, setSize] = useState({
      x: window.innerWidth,
      y: window.innerHeight,
    });
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const { state } = useLocation();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const handleClick = async () => {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          email: email,
          password: password,
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          URLs.domain + "/api/admin-login",
          requestOptions
        );
        const json = await response.json();
        console.log(json);
        if (json.success == true) {
          console.log(json.data);
          login();
          // navigate("/Dashboard");
        }
      } catch (err) {
        console.log(err);
      }
    };
    const login = () => {
      fakeAuth.authenticate(() => {
        setRedirectToReferrer(true);
        localStorage.setItem("authentication", "true");
      });
    };
    if (redirectToReferrer === true) {
      return <Redirect to={state?.from || "/"} />;
    }
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: 500,
            height: 500,
            alignSelf: "center",
          }}
        >
          <form>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Email ID*"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="Password"
                placeholder="Password*"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="button"
              className="btn"
              style={{ marginRight: 10 }}
              onClick={handleClick}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  };

  // const ga = useGA4React();
  const maintainanceMode = false;
  if (maintainanceMode) {
    return <Maintainance />;
  }

  return redirect === true ? (
    <DeepLinking redirect={redirect} setRedirect={setRedirect} />
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ScrollToTop>
            <Switch history={history}>
              <PrivateRoute path="/Dashboard">
                <DashboardSideBar />
              </PrivateRoute>
              <Route exact path="/DashLogin" component={DashLogin} />
              {/* <Route exact path="/Dashboard" component={DashboardSideBar} /> */}

              {/* <Route path="header" component={<DashHeader />} /> */}

              <Route exact path="/" component={DinerHomepage} />
              <Route exact path="/party-order" component={PartyOrderScreen} />
              <Route exact path="/tiffin-services" component={TiffinServiceScreen} />
              <Route exact path="/media-events" component={MediaEventsPage} />
              <Route exact path="/chef-homepage" component={LandingPage} />
              <Route
                exact
                path="/user-selection"
                component={UserSelectionPage}
              />
              <Route exact path="/chef-aboutus" component={AboutUsChefPage} />
              <Route exact path="/diner-aboutus" component={AboutUsDinerPage} />

              <Route exact path="/pick-city" component={PickCityPage} />
              <Route exact path="/signup" component={SignupPage} />
              <Route
                exact
                path="/signin-with-otp"
                component={LoginWithOTPPage}
              />
              <Route
                exact
                path="/signin-with-password"
                component={LoginWithPasswordPage}
              />
              <Route exact path="/verfiyotp" component={VerifyOTPPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/verfiyMobileOtp"
                component={verfiyMobileOTPPage}
              />
              <Route exact path="/chef-faq" component={FAQPage} />
              <Route exact path="/chef-policy" component={PolicyPage} />
              <Route exact path="/chef-terms-of-use" component={TermsPage} />
              <Route exact path="/get-location" component={FetchLocationPage} />
              <Route exact path="/blog" component={BlogsScreen} />
              <Route exact path="/new-blog-page" component={NewBlogDetails} />
              <Route exact path="/15-top-biryani-home-chefs-in-delhi-ncr" component={NewBlogPage1} />
              <Route exact path="/top-home-chefs-in-noida" component={NewBlogPage2} />
              <Route exact path="/top-home-cook-in-bangalore" component={NewBlogPage3} />
              <Route exact path="/blog/:slug" component={BlogDetail} />
              <Route exact path="/blog-static" component={BlogDetailStatic} />

              <Route path="/thank-you" component={ThankYouPage} />
              <Route path="/tiffin-service-thank-you" component={TiffinThankYouPage} />
              <Route path="/party-order-thank-you" component={PartyThankYouPage} />

              <Route exact path="/home-chefs-near-me" component={HomeChefsNearMe} />


              <Route exact path="/online-homemade-food" component={onlineHomemadeFood} />
              <Route exact path="/home-cooked-meal-near-me" component={HomeCookedMeal} />
              <Route exact path="/cooked-app" component={CookedApp} />
              <Route exact path="/home-chefs" component={HomeChefs} />
              <Route exact path="/cooks-app" component={CooksApp} />
              <Route exact path="/home-cooked-food" component={HomeCookedFood} />
              <Route exact path="/food-chef" component={FoodChefs} />
              <Route exact path="/chef-food" component={ChefFood} />


              <Route exact path="/:slug" component={ArticleData} />
              {/* <Route path="*" component={NotFound} /> */}
            </Switch>
            <NotificationContainer />
          </ScrollToTop>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
