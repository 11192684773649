import React, { useState, useRef, useEffect } from "react";
import "../BackendStyle.css";
import { URLs } from "../Url";
// import JoditEditor from "jodit-react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import {
  GetPages,
  handlePostImage,
  Metatagsupload,
  Metatagsupdate,
  handledeleteImage,
  DeletePages,
} from "./config";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Blogpostimage } from "../Blog/config";
function Pages() {
  const editor = useRef(null);

  const [data, setdata] = useState([]);
  const [name, setname] = useState();
  const [slug, setslug] = useState();
  const [title, settitle] = useState();
  const [viewAll, setviewAll] = useState();
  const [status, setstatus] = useState(true);
  const [description, setdescription] = useState();
  const [image, setimage] = useState();
  const [updateimage, setupdateimage] = useState();
  const [deleteimage, setdeleteimage] = useState();

  const [chefs, setChefs] = useState([{ image: "", title: "", features: "", desc: "", link: "" }]);


  const [metatitle, setmetatitle] = useState();
  const [metadescription, setmetadescription] = useState();
  const [metakeyword, setmetakeyword] = useState();
  const [metaogtitle, setmetaogtitle] = useState();
  const [metaogdescription, setmetaogdescription] = useState();
  const [metaogimage, setmetaogimage] = useState();
  const [metaogupdateimage, setmetaogupdateimage] = useState();
  const [metaogdeleteimage, setmetaogdeleteimage] = useState();

  const [UpdateId, setUpdateId] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [show, setShow] = useState(true);
  const [check, setcheck] = useState();

  useEffect(() => {
    handlerData();
  }, []);
  const handlerData = async () => {
    const getgteanData = await GetPages();
    if (getgteanData.success == true) {
      console.log(getgteanData);
      setdata(getgteanData.data);
    }
  };
  const handlerUpdateData = async () => {
    let finalImage;
    let finalImage2;
    if (updateimage != undefined) {
      const GetData = await handlePostImage(image);
      if (GetData.success == true) {
        finalImage = GetData.image;
      }
    }
    if (metaogupdateimage != undefined) {
      const GetData = await handlePostImage(metaogimage);
      if (GetData.success == true) {
        finalImage2 = GetData.image;
      }
    }

    const ImageCheck = updateimage == undefined ? image : finalImage;
    const ImageCheck2 =
      metaogupdateimage == undefined ? metaogimage : finalImage2;

    const getgteanData = await Metatagsupdate(
      UpdateId,
      name,
      slug,
      title,
      description,
      status,
      ImageCheck,
      chefs,
      // metatitle,
      // metadescription,
      // metakeyword,
      // metaogtitle,
      // metaogdescription,
      // ImageCheck2
    );
    if (getgteanData.success == true) {
      handlerData();
      if (updateimage != undefined) {
        ImageDeleteHandler(deleteimage);
      }
      if (metaogupdateimage != undefined) {
        ImageDeleteHandler(metaogdeleteimage);
      }
      UpdateVar();
      setShow(true);
    }
  };
  const handleImage = async () => {
    let finalImage;
    let finalImage2;
    if (image != undefined) {
      const GetData = await handlePostImage(image);
      if (GetData.success == true) {
        finalImage = GetData.image;
      }
    }
    if (metaogimage != undefined) {
      const GetData = await handlePostImage(metaogimage);
      if (GetData.success == true) {
        finalImage2 = GetData.image;
      }
    }
    if (show == "update") {
      handlerUpdateData(finalImage, finalImage2);
    } else {
      handlerUploadData(finalImage, finalImage2);
    }
  };
  const handlerUploadData = async (finalImage, finalImage2) => {
    const getgteanData = await Metatagsupload(
      name,
      slug,
      title,
      description,
      status,
      finalImage,
      chefs,
      // metatitle,
      // metadescription,
      // metakeyword,
      // metaogtitle,
      // metaogdescription,
      // finalImage2
    );
    console.log(getgteanData);
    if (getgteanData.success == true) {
      handlerData();

      setShow(true);
    }
  };
  const ImageDeleteHandler = async (imageDelete) => {
    const getResponse = await handledeleteImage(imageDelete);
    if (getResponse.success == true) {
      UpdateVar();
    }
  };
  const DeleteHandler = async (id, imageDlt) => {
    const getResponses = await DeletePages(id);
    if (getResponses.success == true) {
      const getResponse = await handledeleteImage(imageDlt);
      if (getResponse.success == true) {
        handlerData();
      }
    }
  };
  const UpdateItem = (item) => {
    setname(item.name);
    setslug(item.slug);
    settitle(item.title);
    setviewAll(item.viewAll);
    setstatus(item.status);
    setdescription(item.description);
    setimage(item.image);
    seteditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(item.description))
      )
    );
    setUpdateId(item._id);
    setShow("update");
  };
  const UpdateVar = () => {
    setname();
    setslug();
    settitle();
    setviewAll();
    setstatus();
    setdescription();
    setimage();
    setupdateimage();
    setdeleteimage();
    seteditorState(EditorState.createEmpty());

    setUpdateId();
  };
  console.log(updateimage);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setupdateimage(URL.createObjectURL(event.target.files[0]));
      setimage(event.target.files[0]);
      setcheck(1);
    }
  };
  // const onImageChangeMeta = (event) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setmetaogupdateimage(URL.createObjectURL(event.target.files[0]));
  //     setmetaogimage(event.target.files[0]);
  //     setcheck(2);
  //   }
  // };

  const onImageChangeChefs = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      const updatedImageURL = URL.createObjectURL(event.target.files[0]);
      const updatedChefs = [...chefs];

      // Update the image field of the corresponding chef
      updatedChefs[index].image = updatedImageURL;

      // Optionally, if you want to store the actual file object:
      updatedChefs[index].file = event.target.files[0];

      // Update the state with the new chefs array
      setChefs(updatedChefs);

      // Set check state (if needed)
      setcheck(2);
    }
  };

  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    seteditorState(editorState);
    setdescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [Value, setValue] = useState();

  const uploadCallback = (file, callback) => {
    // console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      console.log(reader);
      reader.onloadend = async () => {
        const getImage = await Blogpostimage(file);
        const res = getImage.image;
        setValue("thumbnail", res);
        resolve({ data: { link: URLs.images + res } });
      };
      reader.readAsDataURL(file);
    });
  };

  const config = {
    image: {
      uploadCallback: uploadCallback,
      alt: { present: true, mandatory: true },
    },
  };


  const [hasChefs, setHasChefs] = useState(false);

  const handleChefChange = (index, event) => {
    const values = [...chefs];
    values[index][event.target.name] = event.target.value;
    setChefs(values);

    console.log(chefs);
  };

  const addChef = () => {
    setChefs([...chefs, { image: "", title: "", features: "", desc: "", link: "" }]);
  };

  const handleCheckboxChange = () => {
    setHasChefs(!hasChefs);
  };

  return (
    <>
      {show == "update" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Name</label>
              <input
                class="form-control"
                value={name}
                onChange={(e) => setname(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                class="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Title  </label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>
              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Write your article here"
                toolbar={config}
              />
            </div>
            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            <div className="form-group">
              {updateimage == undefined ? (
                <img
                  src={URLs.images + image}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              )}
            </div>

            <div className="form-group">
              <label>Chefs</label>
              {chefs.map((chef, index) => (
                <div key={index}>
                  <div className="form-group">
                    <input
                      type="file"
                      name="image"
                      className="form-control"
                      onChange={(event) => onImageChangeChefs(event, index)}
                    />
                    <img src={chef.image} alt={`Chef ${index + 1}`} />

                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Title"
                      value={chef.title}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="features"
                      className="form-control"
                      placeholder="Features"
                      value={chef.features}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="desc"
                      className="form-control"
                      placeholder="Description"
                      value={chef.desc}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="link"
                      className="form-control"
                      placeholder="Link"
                      value={chef.link}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>
                </div>
              ))}
              <button type="button"
                className="form-control"
                onClick={addChef}>
                Add Chef
              </button>
            </div>


            {/* <div className="form-group">
              <label>Meta Title</label>
              <input
                class="form-control"
                value={metatitle}
                onChange={(e) => setmetatitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta Description</label>
              <input
                class="form-control"
                value={metadescription}
                onChange={(e) => setmetadescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta keywords</label>
              <input
                class="form-control"
                value={metakeyword}
                onChange={(e) => setmetakeyword(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Title</label>
              <input
                class="form-control"
                value={metaogtitle}
                onChange={(e) => setmetaogtitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Description</label>
              <input
                class="form-control"
                value={metaogdescription}
                onChange={(e) => setmetaogdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChangeMeta}
              />
            </div>
            <div className="form-group">
              {metaogupdateimage == undefined ? (
                <img
                  src={URLs.images + metaogimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              ) : (
                <img
                  src={metaogupdateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              )}
            </div> */}
            <button
              onClick={() => {
                handlerUpdateData();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : show == "add" ? (
        <div className="cus-contact">
          <form>
            {/* <h3>About Us</h3> */}
            <div className="form-group">
              <label>Name</label>
              <input
                class="form-control"
                value={name}
                onChange={(e) => setname(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Slug</label>
              <input
                class="form-control"
                value={slug}
                onChange={(e) => setslug(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Title</label>
              <input
                class="form-control"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Description</label>

              <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                placeholder="Write your article here"
                toolbar={config}
              />
              {/* <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
              /> */}

              {/* <input
                class="form-control"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></input> */}
            </div>

            <div className="form-group">
              <label>Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChange}
              />
            </div>
            {updateimage != undefined ? (
              <div className="form-group">
                <img
                  src={updateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            ) : null}

            <div className="form-group">
              <label>
                <input type="checkbox" checked={hasChefs} onChange={handleCheckboxChange} />
                Do you want to add chefs?
              </label>
            </div>

            {hasChefs &&

              chefs.map((chef, index) => (
                <div key={index}>

                  <div className="form-group">
                    <label>view All Link </label>
                    <input
                      class="form-control"
                      value={viewAll}
                      onChange={(e) => setviewAll(e.target.value)}
                    ></input>
                  </div>

                  <div className="form-group">

                    <input
                      type="file"
                      name="image"
                      className="form-control"
                      onChange={(event) => onImageChangeChefs(event, index)}
                    />
                    <img src={chef.image} alt={`Chef ${index + 1}`} />


                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      placeholder="Title"
                      value={chef.title}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="features"
                      className="form-control"
                      placeholder="Features"
                      value={chef.features}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      name="desc"
                      className="form-control"
                      placeholder="Description"
                      value={chef.desc}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="link"
                      className="form-control"
                      placeholder="Link"
                      value={chef.link}
                      onChange={(event) => handleChefChange(index, event)}
                    />
                  </div>
                </div>
              ))}

            {hasChefs && (
              <div>
                <button type="button"
                  className="btn btn-dark"

                  onClick={addChef}>
                  Add Another Chef
                </button>
              </div>
            )}

            {/* <div className="form-group">
              <label>Meta Title</label>
              <input
                class="form-control"
                value={metatitle}
                onChange={(e) => setmetatitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta Description</label>
              <input
                class="form-control"
                value={metadescription}
                onChange={(e) => setmetadescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta keywords</label>
              <input
                class="form-control"
                value={metakeyword}
                onChange={(e) => setmetakeyword(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Title</label>
              <input
                class="form-control"
                value={metaogtitle}
                onChange={(e) => setmetaogtitle(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>OG Description</label>
              <input
                class="form-control"
                value={metaogdescription}
                onChange={(e) => setmetaogdescription(e.target.value)}
              ></input>
            </div>
            <div className="form-group">
              <label>Meta Image</label>
              <input
                type="file"
                className="form-control"
                // value={link2}
                name="image"
                onChange={onImageChangeMeta}
              />
            </div>
            {metaogupdateimage != undefined ? (
              <div className="form-group">
                <img
                  src={metaogupdateimage}
                  alt=""
                  className="img-fluid"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            ) : null} */}
            <button
              onClick={() => {
                handleImage();
              }}
              type="button"
              className="btn btn-dark"
            >
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="row table-invoice">
            <div className="col-sm-12">
              <div className="table-in-card">
                <div class="d-flex justify-content-between">
                  <h3>Pages </h3>

                  <button
                    type="button"
                    onClick={() => setShow("add")}
                    class="btn btn-primary btn-xs"
                  >
                    <small>Add</small>
                  </button>
                </div>
                {data.length > 0 ? (
                  <>
                    <br />

                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Slug</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                            {/* <th>OG Image</th>
                            <th>Meta Title</th>
                            <th>Meta Description</th>
                            <th>Keywords</th>
                            <th>OG Title</th>
                            <th>OG Description</th> */}
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.length == 0
                            ? null
                            : data.map((item) => {
                              const editorSta = EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                  convertFromHTML(
                                    item.description.slice(0, 1000)
                                  )
                                )
                              );
                              return (
                                <tr>
                                  <td>
                                    <img
                                      src={URLs.images + item.image}
                                      alt=""
                                      className="img-fluid"
                                      style={{ width: 100, height: 100 }}
                                    />
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.slug}</td>
                                  <td>{item.title}</td>
                                  <td>
                                    <Editor
                                      toolbarHidden
                                      editorState={editorSta}
                                      readOnly
                                    />
                                  </td>
                                  <td>{item.status}</td>

                                  {/* <td>
                                      <img
                                        src={URLs.images + item.meta.ogimage}
                                        alt=""
                                        className="img-fluid"
                                        style={{ width: 100, height: 100 }}
                                      />
                                    </td>
                                    <td>{item.meta.title}</td>
                                    <td>
                                      {item.meta.description == undefined
                                        ? null
                                        : item.meta.description.slice(0, 100)}
                                    </td>
                                    <td>{item.meta.keywords}</td>
                                    <td>{item.meta.ogtitle}</td>
                                    <td>
                                      {item.meta.ogdescription == undefined
                                        ? null
                                        : item.meta.ogdescription.slice(0, 100)}
                                    </td> */}
                                  <td>
                                    <div class="d-flex justify-content-between">
                                      <button
                                        type="button"
                                        onClick={() => UpdateItem(item)}
                                        class="btnnnnn btn-primary"
                                        style={{ marginRight: 10 }}
                                      >
                                        <i class="fa fa-edit"></i>
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          DeleteHandler(item._id, item.image)
                                        }
                                        class="btnnnnn btn-danger"
                                      >
                                        <i class="fa fa-trash"></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pages;
