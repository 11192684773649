/*
#############################################################################
# Name : fetchLocation.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to fetch the location from the GoogleMap 
               and display it in the dropdown section.
#############################################################################
*/
/*global google*/
import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import WhatsAppButton from "../../components/whatsappButton";
import config from "../../config/config";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Link } from "react-router-dom";
import { getUserLocationAPI } from "../../core/services/APIservice";
import {
  updateDinerLocationAPI,
  locationCheck,
} from "../../core/services/Phase2APIservice";
import { NotificationManager } from "react-notifications";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class FetchLocationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      location: "",
      locationName: "",
      locationImage: "",
      latitude: "",
      longitude: "",
      mapLatitude: "",
      mapLongitude: "",
      showMap: false,
      displayLocationPopup: false,
      markers: [
        {
          name: "",
          position: {
            lat: "",
            lng: "",
          },
        },
      ],
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      fullAddress: "",
      landMark: "",
      addressSaveAs: "Home",
      otherText: "",
      pincode: "",
      dragLat: "",
      dragLng: "",
      userId: "",
      formErrors: {
        fullAddress: "",
      },
      prevCitySelected: "",
      prevLocationId: "",
    };
    this.handleMapPopupChange = this.handleMapPopupChange.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    Geocode.setApiKey(config.placesKey); //Geocode setAPIKey used to pass google places key
    this.setState({
      userId: localStorage.getItem("userID"),
    });
    if (this.props.location.search === "?edit-address") {
      let editAddressArr = JSON.parse(localStorage.getItem("editAddressArray"));
      var city = editAddressArr.city;
      this.setState({
        dragLat: editAddressArr.latitude,
        dragLng: editAddressArr.longitude,
        citySeleted: editAddressArr.city,
        address: editAddressArr.location,
        pincode: editAddressArr.pincode,
        addressSaveAs: editAddressArr.type,
        otherText: "",
        fullAddress: editAddressArr.address_line1,
        landMark: editAddressArr.address_line2,
        prevCitySelected: editAddressArr.city,
        prevLocationId: editAddressArr.location_id,
        locationImage: editAddressArr.city_image,
        location: editAddressArr.city,
        locationName: editAddressArr.city,
      });
      /* Geocode function used to fetch the latitude and longitude from the selected address */
      geocodeByAddress(editAddressArr.location)
        .then((results) => {
          getLatLng(results[0]);
          this.getLocationLatLng(editAddressArr.location);
          /*  setTimeout(() => {
            this.setState({ showMap: true });
          }, 1000); */
        })
        .catch((error) => console.error("Error", error));
    } else if (this.props.location.search === "?checkout") {
      this.setState({
        dragLat: localStorage.getItem("longitude"),
        dragLng: localStorage.getItem("latitude"),
        citySeleted: localStorage.getItem("selectedLocation"),
        address: localStorage.getItem("addressSelected"),
        pincode: localStorage.getItem("pincode"),
        addressSaveAs: localStorage.getItem("addressType"),
        otherText: "",
        fullAddress: localStorage.getItem("flatNo"),
        landMark: localStorage.getItem("landmark"),
        prevCitySelected: localStorage.getItem("selectedLocation"),
        prevLocationId: "",
        locationImage: localStorage.getItem("selectedLocationImage"),
        location: localStorage.getItem("selectedLocation"),
        locationName: localStorage.getItem("selectedLocation"),
      });
      var city = localStorage.getItem("selectedLocation");
      /* Geocode function used to fetch the latitude and longitude from the selected address */
      geocodeByAddress(localStorage.getItem("addressSelected"))
        .then((results) => {
          getLatLng(results[0]);
          this.getLocationLatLng(localStorage.getItem("addressSelected"));
          /*  setTimeout(() => {
            this.setState({ showMap: true });
          }, 1000); */
        })
        .catch((error) => console.error("Error", error));
    } else {
      this.setState({
        location: localStorage.getItem("tempSelectedLocation"),
        locationName: localStorage.getItem("tempSelectedLocationName"),
        locationImage: localStorage.getItem("selectedLocationImage"),
      });
      var city = localStorage.getItem("tempSelectedLocation");
    }

    /* Geocode function used to fetch the latitude and longitude from the selected city name */
    Geocode.fromAddress(city).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({
          latitude: lat,
          longitude: lng,
        });
      },
      (error) => {
        console.error(error);
      }
    );
    if (this.props.history.action === "POP") {
      // custom back button implementation
      this.props.history.push("/pick-city");
    }
  }

  /* handleMapPopupChange function starts here */
  handleMapPopupChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "fullAddress":
        formErrors.fullAddress = value === "" ? "Input is required" : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
  /* handleMapPopupChange function ends here */

  /* handleRadioChange function starts here */
  handleRadioChange(event) {
    this.setState({ addressSaveAs: event.target.value });
  }
  /* handleRadioChange function ends here */

  /* getLocationLatLng function starts here */
  getLocationLatLng = (userAddress) => {
    Geocode.fromAddress(userAddress).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.setState({
          mapLatitude: lat,
          mapLongitude: lng,
          dragLat: lat,
          dragLng: lng,
          markers: [
            {
              name: "",
              position: {
                lat: lat,
                lng: lng,
              },
            },
          ],
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  /* getLocationLatLng function ends here */

  /* getGPSLocation function starts here */
  getGPSLocation = (event) => {
    event.preventDefault();
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position);
      this.setState({
        dragLat: position.coords.latitude,
        dragLng: position.coords.longitude,
        mapLatitude: position.coords.latitude,
        mapLongitude: position.coords.longitude,
        markers: [
          {
            name: "",
            position: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          },
        ],
      });
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          const address = response.results[0].formatted_address;
          this.setState({
            address: address,
            /*  showMap: true, */
            selectedPlace: address,
          });
          response.results[0].address_components.forEach((item) => {
            item.types.forEach((type) => {
              if (type === "administrative_area_level_2") {
                localStorage.setItem("tempSelectedLocation1", item.long_name);
                localStorage.setItem(
                  "tempSelectedLocationName1",
                  item.long_name
                );
              }
              if (type === "postal_code") {
                this.setState({
                  pincode: item.long_name,
                });
              }
              /* if (
                item.long_name === localStorage.getItem("tempSelectedLocation")
              ) {
                this.setState({
                  displayLocationPopup: true,
                });
              } else {
                this.setState({
                  displayLocationPopup: false,
                });
              } */
            });
          });

          /*  if (this.state.displayLocationPopup) { */
          if (this.state.userId) {
            var token = localStorage.getItem("accessToken");
            var tempCity = localStorage.getItem("tempSelectedLocation");
            return getUserLocationAPI(
              this.state.userId,
              token,
              this.state.dragLat,
              this.state.dragLng,
              tempCity,
              this.state.address,
              this.state.pincode,
              "Other",
              "",
              "",
              ""
            )
              .then((response) => {
                if (response.data["status"] === 200) {
                  localStorage.setItem(
                    "prevLocationId",
                    response.data.diner_location_id
                  );
                  localStorage.setItem("addressSelected", this.state.address);
                  localStorage.setItem("pincode", this.state.pincode);
                  localStorage.setItem("latitude", this.state.dragLat);
                  localStorage.setItem("longitude", this.state.dragLng);
                  localStorage.setItem("addressType", "Other");
                  var tempCity = localStorage.getItem("tempSelectedLocation");
                  localStorage.setItem("selectedLocation", tempCity);
                  localStorage.setItem("selectedLocationName", tempCity);
                  localStorage.removeItem("tempSelectedLocation");
                  localStorage.removeItem("tempSelectedLocationName");
                  localStorage.removeItem("flatNo");
                  localStorage.removeItem("landmark");
                  localStorage.removeItem("addressDetailType");
                  let selectedCity = config.slugify(tempCity);
                  if (selectedCity == localStorage.getItem("chefCity")) {
                    this.props.history.push(
                      "/" +
                        localStorage.getItem("chefCity") +
                        "/chef-inner-homepage/" +
                        localStorage.getItem("chefSlug")
                    );
                  } else {
                    this.props.history.push(
                      "/" + selectedCity + "/diner-homepage"
                    );
                  }
                  NotificationManager.success(
                    response.data["message"],
                    "Successful!",
                    3000
                  );
                } else {
                  if (response.status === 401) {
                    NotificationManager.error(
                      "Session expired. Please log in.",
                      "Error!"
                    );
                    this.props.history.push("/diner-signin-with-otp");
                    localStorage.clear();
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log(localStorage.getItem("selectedLocation"));
            localStorage.setItem("addressSelected", this.state.address);
            localStorage.setItem("pincode", this.state.pincode);
            localStorage.setItem("latitude", this.state.dragLat);
            localStorage.setItem("longitude", this.state.dragLng);
            localStorage.setItem("addressType", "Other");
            var tempCity = localStorage.getItem("tempSelectedLocation");
            localStorage.setItem("selectedLocation", tempCity);
            localStorage.setItem("selectedLocationName", tempCity);
            localStorage.removeItem("tempSelectedLocation");
            localStorage.removeItem("tempSelectedLocationName");
            localStorage.removeItem("flatNo");
            localStorage.removeItem("landmark");
            localStorage.removeItem("addressDetailType");
            let selectedCity = config.slugify(tempCity);
            if (selectedCity == localStorage.getItem("chefCity")) {
              this.props.history.push(
                "/" +
                  localStorage.getItem("chefCity") +
                  "/chef-inner-homepage/" +
                  localStorage.getItem("chefSlug")
              );
            } else {
              this.props.history.push("/" + selectedCity + "/diner-homepage");
            }
          }
          /* } else {
            this.setState({
              displayLocationPopup: true,
            });
          } */
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };
  /* getGPSLocation function ends here */

  /* handleChange function starts here */
  handleChange = (address) => {
    this.setState({ address });
  };
  /* handleChange function ends here */

  /* handleSelect function starts here */
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(async (latLng) => {
        this.getLocationLatLng(address.address);
        this.setState({
          address: address,
          lat: latLng.lat,
          long: latLng.lng,
        });
        if (this.state.userId) {
          var token = localStorage.getItem("accessToken");
          var tempCity = localStorage.getItem("tempSelectedLocation");
          try {
            const response = await getUserLocationAPI(
              this.state.userId,
              token,
              this.state.lat,
              this.state.long,
              tempCity,
              this.state.address,
              this.state.pincode,
              "Other",
              "",
              "",
              ""
            );
            if (response.data["status"] === 200) {
              localStorage.setItem(
                "prevLocationId",
                response.data.diner_location_id
              );
              localStorage.setItem("addressSelected", this.state.address);
              localStorage.setItem("pincode", this.state.pincode);
              localStorage.setItem("latitude", this.state.lat);
              localStorage.setItem("longitude", this.state.long);
              localStorage.setItem("addressType", "Other");
              var tempCity = localStorage.getItem("tempSelectedLocation");
              localStorage.setItem("selectedLocation", tempCity);
              localStorage.setItem("selectedLocationName", tempCity);
              localStorage.removeItem("tempSelectedLocation");
              localStorage.removeItem("tempSelectedLocationName");
              localStorage.removeItem("flatNo");
              localStorage.removeItem("landmark");
              localStorage.removeItem("addressDetailType");
              let selectedCity = config.slugify(tempCity);
              if (selectedCity == localStorage.getItem("chefCity")) {
                this.props.history.push(
                  "/" +
                    localStorage.getItem("chefCity") +
                    "/chef-inner-homepage/" +
                    localStorage.getItem("chefSlug")
                );
              } else {
                this.props.history.push("/" + selectedCity + "/diner-homepage");
              }
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
            } else {
              if (response.status === 401) {
                NotificationManager.error(
                  "Session expired. Please log in.",
                  "Error!"
                );
                this.props.history.push("/diner-signin-with-otp");
                localStorage.clear();
              }
            }
          } catch (error) {}
        } else {
          localStorage.setItem("addressSelected", this.state.address);
          localStorage.setItem("pincode", this.state.pincode);
          localStorage.setItem("latitude", this.state.lat);
          localStorage.setItem("longitude", this.state.long);
          localStorage.setItem("addressType", "Other");
          var tempCity = localStorage.getItem("tempSelectedLocation");
          localStorage.setItem("selectedLocation", tempCity);
          localStorage.setItem("selectedLocationName", tempCity);
          localStorage.removeItem("tempSelectedLocation");
          localStorage.removeItem("tempSelectedLocationName");
          localStorage.removeItem("flatNo");
          localStorage.removeItem("landmark");
          localStorage.removeItem("addressDetailType");
          let selectedCity = config.slugify(tempCity);
          if (selectedCity == localStorage.getItem("chefCity")) {
            this.props.history.push(
              "/" +
                localStorage.getItem("chefCity") +
                "/chef-inner-homepage/" +
                localStorage.getItem("chefSlug")
            );
          } else {
            this.props.history.push("/" + selectedCity + "/diner-homepage");
          }
        }
      })
      .catch((error) => console.error("Error", error));
  };
  /* handleSelect function ends here */

  /* handleClick function starts here */
  handleClick(address) {
    geocodeByAddress(address.description)
      .then((results) => getLatLng(results[0]))
      .then(async (latLng) => {
        this.getLocationLatLng(address.description);
        this.setState({
          address: address.description,
          lat: latLng.lat,
          long: latLng.lng,
        });
        if (this.state.userId) {
          var token = localStorage.getItem("accessToken");
          var tempCity = localStorage.getItem("tempSelectedLocation");
          try {
            const response = await getUserLocationAPI(
              this.state.userId,
              token,
              this.state.lat,
              this.state.long,
              tempCity,
              this.state.address,
              this.state.pincode,
              "Other",
              "",
              "",
              ""
            );
            if (response.data["status"] === 200) {
              localStorage.setItem(
                "prevLocationId",
                response.data.diner_location_id
              );
              localStorage.setItem("addressSelected", this.state.address);
              localStorage.setItem("pincode", this.state.pincode);
              localStorage.setItem("latitude", this.state.lat);
              localStorage.setItem("longitude", this.state.long);
              localStorage.setItem("addressType", "Other");
              var tempCity = localStorage.getItem("tempSelectedLocation");
              localStorage.setItem("selectedLocation", tempCity);
              localStorage.setItem("selectedLocationName", tempCity);
              localStorage.removeItem("tempSelectedLocation");
              localStorage.removeItem("tempSelectedLocationName");
              localStorage.removeItem("flatNo");
              localStorage.removeItem("landmark");
              localStorage.removeItem("addressDetailType");
              let selectedCity = config.slugify(tempCity);
              if (selectedCity == localStorage.getItem("chefCity")) {
                this.props.history.push(
                  "/" +
                    localStorage.getItem("chefCity") +
                    "/chef-inner-homepage/" +
                    localStorage.getItem("chefSlug")
                );
              } else {
                this.props.history.push("/" + selectedCity + "/diner-homepage");
              }
              NotificationManager.success(
                response.data["message"],
                "Successful!",
                3000
              );
            } else {
              if (response.status === 401) {
                NotificationManager.error(
                  "Session expired. Please log in.",
                  "Error!"
                );
                this.props.history.push("/diner-signin-with-otp");
                localStorage.clear();
              }
            }
          } catch (error) {}
        } else {
          localStorage.setItem("addressSelected", this.state.address);
          localStorage.setItem("pincode", this.state.pincode);
          localStorage.setItem("latitude", this.state.lat);
          localStorage.setItem("longitude", this.state.long);
          localStorage.setItem("addressType", "Other");
          var tempCity = localStorage.getItem("tempSelectedLocation");
          localStorage.setItem("selectedLocation", tempCity);
          localStorage.setItem("selectedLocationName", tempCity);
          localStorage.removeItem("tempSelectedLocation");
          localStorage.removeItem("tempSelectedLocationName");
          localStorage.removeItem("flatNo");
          localStorage.removeItem("landmark");
          localStorage.removeItem("addressDetailType");
          let selectedCity = config.slugify(tempCity);
          if (selectedCity == localStorage.getItem("chefCity")) {
            this.props.history.push(
              "/" +
                localStorage.getItem("chefCity") +
                "/chef-inner-homepage/" +
                localStorage.getItem("chefSlug")
            );
          } else {
            this.props.history.push("/" + selectedCity + "/diner-homepage");
          }
        }
      })
      .catch((error) => console.error("Error", error));
  }
  /* handleClick function ends here */

  /* closeMapPopup function starts here */
  closeMapPopup = (event) => {
    event.preventDefault();
    this.setState({
      showMap: false,
      address: "",
    });
  };
  /* closeMapPopup function ends here */

  /* moveMarker function starts here */
  moveMarker = (props, marker, e, index) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    this.setState({
      dragLat: lat,
      dragLng: lng,
    });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.setState({
          selectedPlace: address,
          activeMarker: marker,
          showingInfoWindow: true,
          address: address,
        });
        response.results[0].address_components.forEach((item) => {
          item.types.forEach((type) => {
            if (type === "postal_code") {
              this.setState({
                pincode: item.long_name,
              });
            }
          });
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  /* moveMarker function ends here */

  /* onClose function starts here */
  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };
  /* onClose function ends here */

  /* saveData function starts here */
  saveData = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.fullAddress === "") {
      formErrors.fullAddress = "Input is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      if (this.state.userId) {
        if (this.props.location.search === "?edit-address") {
          var token = localStorage.getItem("accessToken");
          let locationData = {
            user_id: this.state.userId,
            location_id: this.state.prevLocationId,
            latitude: this.state.dragLat,
            longitude: this.state.dragLng,
            city: this.state.prevCitySelected,
            location: this.state.address,
            pincode: this.state.pincode,
            type: this.state.addressSaveAs,
            location_title: this.state.otherText,
            address_line1: this.state.fullAddress,
            address_line2: this.state.landMark,
          };
          return updateDinerLocationAPI(locationData, token)
            .then((response) => {
              if (response.data["status"] === 200) {
                localStorage.setItem("flatNo", this.state.fullAddress);
                localStorage.setItem("landmark", this.state.landMark);
                localStorage.setItem("addressSelected", this.state.address);
                localStorage.setItem("addressType", this.state.addressSaveAs);
                localStorage.setItem("pincode", this.state.pincode);
                localStorage.setItem("latitude", this.state.dragLat);
                localStorage.setItem("longitude", this.state.dragLng);
                this.props.history.push("/manage-address");
                NotificationManager.success(
                  response.data["message"],
                  "Successful!",
                  3000
                );
              } else {
                if (response.status === 401) {
                  NotificationManager.error(
                    "Session expired. Please log in.",
                    "Error!"
                  );
                  this.props.history.push("/diner-signin-with-otp");
                  localStorage.clear();
                }
              }
            })
            .catch((error) => {
              /* NotificationManager.error(error, "Error!"); */
            });
        } else {
          var token = localStorage.getItem("accessToken");
          var citySeleted = "";
          if (this.props.location.search === "?checkout") {
            citySeleted = localStorage.getItem("selectedLocation");
          } else {
            citySeleted = localStorage.getItem("tempSelectedLocation");
          }
          if (this.state.landMark != "") {
            var addressSelected =
              this.state.fullAddress +
              ", " +
              this.state.landMark +
              ", " +
              this.state.address;
          } else {
            var addressSelected =
              this.state.fullAddress + ", " + this.state.address;
          }

          return getUserLocationAPI(
            this.state.userId,
            token,
            this.state.dragLat,
            this.state.dragLng,
            citySeleted,
            addressSelected,
            this.state.pincode,
            this.state.addressSaveAs,
            this.state.otherText,
            this.state.fullAddress,
            this.state.landMark
          )
            .then((response) => {
              if (response.data["status"] === 200) {
                if (this.props.location.search === "?checkout") {
                  localStorage.setItem("selectedLocation", citySeleted);
                  localStorage.setItem("selectedLocationName", citySeleted);
                } else {
                  var tempCity = localStorage.getItem("tempSelectedLocation");
                  localStorage.setItem("selectedLocation", tempCity);
                  localStorage.setItem("selectedLocationName", tempCity);
                }

                localStorage.setItem("flatNo", this.state.fullAddress);
                localStorage.setItem("landmark", this.state.landMark);
                localStorage.setItem("addressSelected", addressSelected);
                localStorage.setItem("addressType", this.state.addressSaveAs);
                localStorage.setItem("pincode", this.state.pincode);
                localStorage.setItem("latitude", this.state.dragLat);
                localStorage.setItem("longitude", this.state.dragLng);
                if (
                  this.state.addressSaveAs == "Other" ||
                  this.state.addressSaveAs == "other"
                ) {
                  localStorage.setItem(
                    "addressDetailType",
                    this.state.otherText
                  );
                }
                if (this.props.location.search === "?add-address") {
                  this.props.history.push("/manage-address");
                } else if (this.props.location.search === "?checkout") {
                  this.props.history.push("/checkout");
                } else {
                  let selectedCity = config.slugify(
                    localStorage.getItem("selectedLocation")
                  );
                  if (selectedCity == localStorage.getItem("chefCity")) {
                    this.props.history.push(
                      "/" +
                        localStorage.getItem("chefCity") +
                        "/chef-inner-homepage/" +
                        localStorage.getItem("chefSlug")
                    );
                  } else {
                    this.props.history.push(
                      "/" + selectedCity + "/diner-homepage"
                    );
                  }
                }
                localStorage.removeItem("tempSelectedLocation");
                localStorage.removeItem("tempSelectedLocationName");
                NotificationManager.success(
                  response.data["message"],
                  "Successful!",
                  3000
                );
              } else {
                if (response.status === 401) {
                  NotificationManager.error(
                    "Session expired. Please log in.",
                    "Error!"
                  );
                  this.props.history.push("/diner-signin-with-otp");
                  localStorage.clear();
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.state.landMark != "") {
          var addressSelected =
            this.state.fullAddress +
            ", " +
            this.state.landMark +
            ", " +
            this.state.address;
        } else {
          var addressSelected =
            this.state.fullAddress + ", " + this.state.address;
        }
        localStorage.setItem("flatNo", this.state.fullAddress);
        localStorage.setItem("landmark", this.state.landMark);
        localStorage.setItem("addressSelected", addressSelected);
        localStorage.setItem("addressType", this.state.addressSaveAs);
        localStorage.setItem("pincode", this.state.pincode);
        localStorage.setItem("latitude", this.state.dragLat);
        localStorage.setItem("longitude", this.state.dragLng);
        var tempCity = localStorage.getItem("tempSelectedLocation");
        localStorage.setItem("selectedLocation", tempCity);
        localStorage.setItem("selectedLocationName", tempCity);
        if (
          this.state.addressSaveAs == "Other" ||
          this.state.addressSaveAs == "other"
        ) {
          localStorage.setItem("addressDetailType", this.state.otherText);
        }
        if (this.props.location.search === "?add-address") {
          this.props.history.push("/manage-address");
        } else if (this.props.location.search === "?checkout") {
          this.props.history.push("/checkout");
        } else {
          this.props.history.push("/diner-homepage");
        }
        localStorage.removeItem("tempSelectedLocation");
        localStorage.removeItem("tempSelectedLocationName");
      }
    } else {
      console.log("error");
    }
  };
  /* saveData function ends here */

  render() {
    const { formErrors } = this.state;
    const searchOptions = {
      location: new google.maps.LatLng(
        this.state.latitude,
        this.state.longitude
      ),
      radius: 5000,
      strictBounds: true,
      componentRestrictions: { country: ["in"] },
      types: ["address"],
    };
    return (
      <div className="css2">
        <DinnerHeader />
        <section className="homepage picked_city">
          {this.props.location.search === "?edit-address" ? null : (
            <Link to={"/pick-city"} className="back_btn">
              <img
                src="/assets/images/icons/black-arrow.svg"
                alt="Black Arrow"
              />
              Back
            </Link>
          )}

          <div className="container">
            {this.props.location.search !== "?checkout" && (
              <div className="big_circle_loc">
                <div className="inner">
                  <img
                    src={this.state.locationImage}
                    alt="Mumbai"
                    className="img-fluid"
                  />
                </div>
              </div>
            )}
            <h2 className="title">{this.state.location}</h2>

            <div className="form_wrap">
              <form>
                <div className="form-group">
                  <label>Enter location</label>
                  <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    searchOptions={searchOptions}
                    data-toggle="modal"
                    data-target="#bottom_modal"
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            "aria-expanded": "false",
                            placeholder: "Search for area, street name",
                            className: "location-search-input form-control",
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map((suggestion, ind) => {
                            var string = suggestion.description;
                            /* console.log(string); */
                            var main_location = string.substr(
                              0,
                              string.indexOf(",")
                            );
                            var address_line = string.replace(
                              main_location + ",",
                              ""
                            );

                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return this.state.locationName == "Delhi NCR" ? (
                              <div
                                key={ind}
                                {...getSuggestionItemProps(
                                  { main_location, address_line },
                                  {
                                    className,
                                    style,
                                  }
                                )}
                                onClick={(e) => {
                                  this.handleClick(suggestion);
                                }}
                                data-toggle="modal"
                                data-target="#bottom_modal"
                              >
                                <p className="locationList">
                                  <span className="textMainLocation">
                                    <img
                                      src="/assets/images/icons/place.png"
                                      className="locationPinImage"
                                    />
                                  </span>
                                  <span>
                                    <span>{main_location}</span>
                                    <span className="subTextLocation">
                                      {address_line}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            ) : suggestion.description.includes(
                                this.state.locationName
                              ) ? (
                              <div
                                key={ind}
                                {...getSuggestionItemProps(
                                  { main_location, address_line },
                                  {
                                    className,
                                    style,
                                  }
                                )}
                                onClick={(e) => {
                                  this.handleClick(suggestion);
                                }}
                                data-toggle="modal"
                                data-target="#bottom_modal"
                              >
                                <p className="locationList">
                                  <span className="textMainLocation">
                                    <img
                                      src="/assets/images/icons/place.png"
                                      className="locationPinImage"
                                    />
                                  </span>
                                  <span>
                                    <span>{main_location}</span>
                                    <span className="subTextLocation">
                                      {address_line}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn gps_btn form-control"
                    onClick={this.getGPSLocation}
                    data-toggle="modal"
                    data-target="#bottom_modal"
                  >
                    <img src="/assets/images/icons/gps.svg" alt="GPS" />
                    Set location using GPS
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div className="nofooter">
          <WhatsAppButton />
        </div>
        {this.state.showMap == true ? (
          <div
            className="modal bottom_pop fade map_pop show"
            id="bottom_modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="bottom_modal"
            style={{ paddingRight: "17px", display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="map-img">
                    <Map
                      google={this.props.google}
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "300px",
                      }}
                      zoom={15}
                      initialCenter={{
                        lat: this.state.markers[0].position.lat,
                        lng: this.state.markers[0].position.lng,
                      }}
                    >
                      {this.state.markers.map((marker, index) => (
                        <Marker
                          key={"bvcdert" + index}
                          position={marker.position}
                          draggable={true}
                          onDragend={this.moveMarker.bind(marker)}
                          name={marker.name}
                          icon={"/map-marker.png"}
                        />
                      ))}
                    </Map>
                  </div>
                  <div className="more-details">
                    <div className="form_wrap edit-delivery-address">
                      <form>
                        <div className="address-header">
                          <p className="small-text">YOUR LOCATION</p>
                          <p className="text-med address-change fw_500">
                            {this.state.address}
                            <button
                              type="submit"
                              data-dismiss="modal"
                              aria-label="Close"
                              className="change-text"
                              onClick={this.closeMapPopup}
                            >
                              Change
                            </button>
                          </p>
                          <h2>Add More Details</h2>
                        </div>
                        <div className="form-group">
                          <label>Enter Building/House/Flat/Block No.*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="fullAddress"
                            value={this.state.fullAddress}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                          <small className="text-danger">
                            {formErrors.fullAddress}
                          </small>
                        </div>
                        <div className="form-group">
                          <label>Enter Landmark</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            name="landMark"
                            value={this.state.landMark}
                            onChange={this.handleMapPopupChange}
                            maxLength="40"
                          />
                        </div>
                        <div className="form-group">
                          <label>Save as</label>
                          <div className="radio-box">
                            <ul className="radio_list">
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Home
                                    <input
                                      type="radio"
                                      name="Home"
                                      onChange={this.handleRadioChange}
                                      value="Home"
                                      checked={
                                        this.state.addressSaveAs === "Home" ||
                                        this.state.addressSaveAs === "home"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Work
                                    <input
                                      type="radio"
                                      name="Work"
                                      onChange={this.handleRadioChange}
                                      value="Work"
                                      checked={
                                        this.state.addressSaveAs === "Work" ||
                                        this.state.addressSaveAs === "work"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="custome_check">
                                  <label className="fw_500">
                                    Other
                                    <input
                                      type="radio"
                                      name="Other"
                                      onChange={this.handleRadioChange}
                                      value="Other"
                                      checked={
                                        this.state.addressSaveAs === "Other" ||
                                        this.state.addressSaveAs === "other"
                                      }
                                    />
                                    <span className="radiobtn"></span>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {this.state.addressSaveAs == "Other" ||
                          this.state.addressSaveAs === "other" ? (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="eg. Dad's Place"
                              name="otherText"
                              value={this.state.otherText}
                              onChange={this.handleMapPopupChange}
                              maxLength="20"
                            />
                          ) : null}
                        </div>
                        <div className="form-group save-btn mb-0">
                          <button
                            type="submit"
                            className="btn"
                            onClick={this.saveData}
                          >
                            Save and Proceed
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.displayLocationPopup ? (
          <div>
            <div
              className="modal fade popup bottom_pop map_pop cancel_order_pop show d-block"
              id="deleteTimeSlotModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="LeaveModalTitle"
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <button
                      type="button"
                      className="close_icon"
                      onClick={() =>
                        this.setState({
                          displayLocationPopup: false,
                        })
                      }
                      aria-label="Close"
                    >
                      <img
                        src="/assets/images/icons/black-close.svg"
                        alt="Close"
                      />
                    </button>
                    <p className="mb-0">
                      Oops! Location is not matching with the city selected.
                    </p>
                    <Link to={"/pick-city"} className="btn mt-3">
                      change location
                    </Link>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}
        {this.state.showMap || this.state.displayLocationPopup ? (
          <div className="modal-backdrop fade show"></div>
        ) : null}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: config.placesKey,
})(FetchLocationPage);
