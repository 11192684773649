/*
#############################################################################
# Name : loginWithOTP.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to check the login with password and verfiy data
#############################################################################
*/
import React, { Component } from 'react';
import Header from '../../components/Header';
import WhatsAppButton from '../../components/whatsappButton';
import ReactFormInputValidation from 'react-form-input-validation';
import { signinAPI, getUserSummaryAPI } from '../../core/services/APIservice';
import { Link, Redirect } from 'react-router-dom';
// React Notification
import { NotificationManager } from 'react-notifications';
import config from '../../config/config';
import Recaptcha from 'react-google-invisible-recaptcha';
import { connect } from 'react-redux';
import { saveUserData } from '../../redux/actions/authActions';

class LoginWithPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        password: ''
      },
      buttonText: 'log in',
      buttonDisabled: false,
      errors: {}
    };
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({ email: 'required|email', password: 'required' });

    /* onformsubmit function starts here */
    this.form.onformsubmit = (fields) => {
      this.setState({
        buttonText: 'logging in...',
        buttonDisabled: true
      });
      this.recaptcha.execute();
      return signinAPI(fields.email, fields.password)
        .then((response) => {
          if (response.data.status === 200) {
            this.setState({
              buttonText: 'log in',
              buttonDisabled: false
            });
            var token = response.data['data']['api_access_token'];
            var userId = response.data['data']['user']['user_id'];
            var userEmail = response.data['data']['user']['email'];
            var userName = response.data['data']['user']['name'];
            var userMobile = response.data['data']['user']['mobile_number'];
            var image = response.data['data']['user']['profile_image'];
            var verified = response.data['data']['profile_verified'];
            var membership = response.data.membership;
            var admin = response.data.is_admin;
            var chef = response.data.is_chef;
            localStorage.setItem('userID', userId);
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('userName', userName);
            localStorage.setItem('userImage', image);
            localStorage.setItem('accessToken', token);
            localStorage.setItem('userMobile', userMobile);
            localStorage.setItem('userVerified', verified);
            localStorage.setItem('userMemberShip', membership);
            let data = {
              userType: response.data.is_chef ? 'Chef' : 'Diner',
              userId: response.data['data']['user']['user_id'],
              userEmail: response.data['data']['user']['email'],
              userName: response.data['data']['user']['name'],
              userMobile: response.data['data']['user']['mobile_number'],
              image: response.data['data']['user']['profile_image'],
              verifid: response.data['data']['user']['profile_verified'],
              accessToken: response.data['data']['api_access_token'],
              isAvailable: response.data.is_available,
              auditMe: response.data.a_m_a_enabled,
              membership: response.data.membership
                ? response.data.membership
                : ''
            };
            this.props.saveUserData(data);
            /*  if (response.data["data"]["profile_verified"] == "0") {
              this.props.history.push("/user-details");
            } else */ if (response.data['data']['profile_verified'] == 1) {
              if (response.data.membership == 'basic') {
                this.props.history.push('/chef-membership');
              } else {
                if (
                  this.props.location.search !== '' &&
                  this.props.location.search.indexOf('redirect=') !== -1
                ) {
                  this.props.history.push(
                    '/' + this.props.location.search.split('=')[1]
                  );
                } else {
                  this.props.history.push('/chef-dashboard');
                }
              }
            } else {
              if (admin == false && chef == true) {
                var token = response.data['data']['api_access_token'];
                var userId = response.data['data']['user']['user_id'];
                var userEmail = response.data['data']['user']['email'];
                var userName = response.data['data']['user']['name'];
                var userMobile = response.data['data']['user']['mobile_number'];
                var image = response.data['data']['user']['profile_image'];
                return getUserSummaryAPI(userId, token)
                  .then((response) => {
                    if (response.data['status'] === 200) {
                      this.setState({
                        userAboutYou: response.data['data']['about_average'],
                        userBusiness: response.data['data']['business_average'],
                        userKitchen: response.data['data']['kitchen_average'],
                        userMenu:
                          response.data['data']['number_of_uploaded_menu']
                      });
                      var setStatus = false;

                      if (
                        response.data['data']['about_average'] < 100 &&
                        setStatus === false
                      ) {
                        localStorage.setItem('currentStatus', 1);
                        setStatus = true;
                      } else if (
                        response.data['data']['business_average'] < 100 &&
                        setStatus === false
                      ) {
                        localStorage.setItem('currentStatus', 2);
                        setStatus = true;
                      } else if (
                        response.data['data']['kitchen_average'] < 100 &&
                        setStatus === false
                      ) {
                        localStorage.setItem('currentStatus', 3);
                        setStatus = true;
                      } else {
                        localStorage.setItem('currentStatus', 4);
                      }
                      this.props.history.push('/user-details');
                    } else if (response.status === 500) {
                      NotificationManager.error(
                        response.data.message,
                        'Error!'
                      );
                    } else {
                      NotificationManager.error(
                        response.data['message'],
                        'Error!'
                      );
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else if (admin == true && chef == false) {
                NotificationManager.error(
                  'You are trying login with Admin Credentials',
                  'Error!'
                );
              } else {
                if (response.data['data']['location'] == null) {
                  this.props.history.push('/pick-city');
                } else {
                  var address = response.data['data']['location'];
                  var addressType = response.data['data']['type'];
                  var latitude = response.data['data']['latitude'];
                  var longtitude = response.data['data']['longitude'];
                  var city = response.data['data']['city'];
                  localStorage.setItem('addressSelected', address);
                  localStorage.setItem('addressType', addressType);
                  localStorage.setItem('latitude', latitude);
                  localStorage.setItem('longitude', longtitude);
                  localStorage.setItem('selectedLocation', city);
                  localStorage.setItem('selectedLocationName', city);
                  var selectedCity = config.slugify(city);
                  this.props.history.push(
                    '/' + selectedCity + '/diner-homepage'
                  );
                }
              }
              NotificationManager.success(
                'Welcome back to CHEFPIN ' + userName,
                'Successful!',
                3000
              );
            }
          } else if (response.data.status === 405) {
            this.setState({
              buttonText: 'log in',
              buttonDisabled: false
            });
            localStorage.setItem('forgot-password-email', fields.email);
            if (response.data.reset_password == true) {
              localStorage.setItem('resetPassword', true);
              this.props.history.push('/forgot-password');
            }
          } else {
            this.setState({
              buttonText: 'log in',
              buttonDisabled: false
            });
            NotificationManager.error(response.data.message, 'Error!');
          }
        })
        .catch((error) => {
          this.setState({
            buttonText: 'log in',
            buttonDisabled: false
          });
          NotificationManager.error('Invalid User', 'Error!');
          this.recaptcha.reset();
        });
    };
    /* onformsubmit function ends here */
  }

  componentDidMount() {
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;
    try {
      OneSignal.getUserId().then(function (userId) {
        localStorage.setItem('OneSignal_playerId', userId);
      });
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        console.log('is subscribed', isSubscribed);
        OneSignal.push(function () {
          console.log('attempt to get id'); // doesn't get this far
          OneSignal.getUserId(function (userId) {
            console.log('user id', userId); // doesn't get this far
            localStorage.setItem('OneSignal_playerId', userId);
          });
        });
      });
    } catch (error) {
      console.log(error);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /* onResolved function starts here */
  onResolved() {
    alert('Recaptcha resolved with response: ' + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    if (localStorage.getItem('accessToken') !== null) {
      return <Redirect to='/user-details' />;
    }
    return (
      <React.Fragment>
        <div>
          <Header />
          <section className='sign_up_chef verification log_otp'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-xl-5 d-flex align-items-center justify-content-center justify-content-lg-start'>
                  <div className='text pt-0'>
                    <h3 className='color_C07'>LOG IN TO CHEF PIN</h3>
                    <div className='profile_img'>
                      <div className='inner'>
                        <img
                          src='/assets/images/icons/profile-img.png'
                          alt='Profile Images'
                          className='img-fluid'
                        />
                      </div>
                    </div>
                    <div className='form_wrap'>
                      <form onSubmit={this.form.handleSubmit}>
                        <div className='form-group'>
                          <input
                            type='email'
                            className='form-control'
                            id='exampleInputEmail1'
                            aria-describedby='emailHelp'
                            placeholder='Email ID*'
                            name='email'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.email}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.email
                              ? this.state.errors.email
                              : ''}
                          </small>
                        </div>
                        <div className='form-group'>
                          <input
                            type='password'
                            className='form-control'
                            id='Password'
                            placeholder='Password*'
                            name='password'
                            onBlur={this.form.handleBlurEvent}
                            onChange={this.form.handleChangeEvent}
                            value={this.state.fields.password}
                          />
                          <small className='form-text red_000'>
                            {this.state.errors.password
                              ? 'Password field is required'
                              : ''}
                          </small>
                          <Link
                            to={'/forgot-password'}
                            className='form-text forgot_password text-right text-muted'
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div className='form-group mb-0'>
                          <button
                            disabled={this.state.buttonDisabled}
                            type='submit'
                            className='btn'
                          >
                            {this.state.buttonText}
                          </button>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-xl-7 align-items-center d-none d-lg-flex'>
                  <div className='img_box'>
                    <img
                      src='/assets/images/sign-up-as-a-chef/sign-up-img.png'
                      alt='sign up img'
                      className='img-fluid'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className='nofooter'>
            <WhatsAppButton />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.items
});

export default connect(mapStateToProps, { saveUserData })(
  LoginWithPasswordPage
);
