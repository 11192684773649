/*
#############################################################################
# Name : Phase2APIservice.js
# Created by : Arvind Yadav
# Date       : 7th Dec 2020
# Purpose    : This file is used to fetch the data through API for Diner's
#############################################################################
*/
import axios from "axios";
import config from "../../config/config";

async function marketingCardsAPI() {
  try {
    const response = await axios.post(config.APIURL + "v1/get-marketing-ads");
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefListingAPI(city, pincode, latitude, longitude, userId) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/homepage-get-chef-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function crowdFavouriteAPI() {
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-crowd-favourite-listing"
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function collectionsAPI() {
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-collection-listing"
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getLocationListAPI(userID) {
  console.log(userID);
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-diner-location-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefBasicListingAPI(city, pincode, latitude, longitude) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-basic-chef-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefPremiumListingAPI(city, pincode, latitude, longitude) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-premium-chef-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefVipListingAPI(city, pincode, latitude, longitude) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-vip-chef-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function vipHomeChefListingAPI(
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/chef/vip",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function mustTryChefListingAPI(
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/chef/must-try-meals",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function popularHomeChefListingAPI(
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/chef/premium",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function newBasicChefListingAPI(
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/chef/basic",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function newOnChefListingAPI(city, pincode, latitude, longitude, userId) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/chef/new-chefpin-members",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function quickFilterAPI(
  city,
  pincode,
  latitude,
  longitude,
  filterData,
  userId
) {
  var formdata = new FormData();
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("filter", filterData);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/quick-filter",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function searchDataAPI(
  searchData,
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("search_term", searchData);
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(config.APIURL + "v1/search", formdata);
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dishDataAPI() {
  try {
    const response = await axios.get(
      config.APIURL + "v1/homepage-top-seo-sliders"
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefDataAPI() {
  try {
    const response = await axios.get(
      config.APIURL + "v1/homepage-bottom-seo-sliders"
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function articleTagAPI() {
  try {
    const response = await axios.get(config.APIURL + "v1/get-seo-article-tags");
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function articleDetailAPI(slugData) {
  try {
    const response = await axios.get(
      config.APIURL + "v1/get-seo-article?slug-url=" + slugData
    );
    console.log("dfvbggfs");
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function cityAPI() {
  try {
    const response = await axios.post(config.APIURL + "v1/get-city");
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function crowdFavaoutireAPI(
  id,
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("crowd_favourite", id);
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/crowd-favourite",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function bannerAPI() {
  try {
    const response = await axios.post(config.APIURL + "v1/get-banner-listing");
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAddressListAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-diner-locations",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getPlayerIDAPI(userID, token, playerId) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("subscription_unique_id", playerId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/subscribe",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChefDetailAPI(chefId, type) {
  var formdata = new FormData();
  formdata.append("chef_id", chefId);
  formdata.append("diet_type", type);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/get-chef-information",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChefDataBySlug(slug, type, userId) {
  var formdata = new FormData();
  formdata.append("slug_url", slug);
  formdata.append("diet_type", type);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/get-chef-information-by-slug",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function collectionDetialAPI(
  id,
  city,
  pincode,
  latitude,
  longitude,
  userId
) {
  var formdata = new FormData();
  formdata.append("collection", id);
  formdata.append("city", city);
  formdata.append("pincode", pincode);
  formdata.append("latitude", latitude);
  formdata.append("longitude", longitude);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/collection",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function enquireMessageAPI(chefId, name, mobile_number, email, message) {
  var formdata = new FormData();
  formdata.append("chef_id", chefId);
  formdata.append("name", name);
  formdata.append("mobile_number", mobile_number);
  formdata.append("email", email);
  formdata.append("message", message);
  try {
    const response = await axios.post(
      config.APIURL + "v1/send-chefpage-messages",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function marketingDetailAPI(marketingId, city, lat, long, userId) {
  var formdata = new FormData();
  formdata.append("marketing_card", marketingId);
  formdata.append("city", city);
  formdata.append("latitude", lat);
  formdata.append("longitude", long);
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/home/marketing-card",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function timeSlotAPI(startTime, endTime) {
  var formdata = new FormData();
  formdata.append("opening_time", startTime);
  formdata.append("closing_time", endTime);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-time-slot",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function taxCalculationAPI(amount, delivary, detailOrder) {
  var formdata = new FormData();
  formdata.append("taxable_amount", amount);
  formdata.append("delivery_charge", delivary);
  formdata.append("orderjson", JSON.stringify(detailOrder));
  try {
    const response = await axios.post(
      config.APIURL + "v1/tax-calculation",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function deliveryChargesAPI(addedData) {
  var data = JSON.stringify({
    points: [
      { address: addedData.strChefAddress },
      { address: addedData.strDinerAddress },
    ],
  });
  var formdata = new FormData();
  formdata.append("location_json", data);
  formdata.append("city", addedData.city);
  formdata.append("pickup_location", addedData.strChefAddress);
  formdata.append("drop_location", addedData.strDinerAddress);
  formdata.append("pickup_lat", addedData.pickup_lat);
  formdata.append("pickup_lng", addedData.pickup_lng);
  formdata.append("drop_lat", addedData.drop_lat);
  formdata.append("drop_lng", addedData.drop_lng);
  formdata.append("selected_time_slot", addedData.selected_time_slot);
  formdata.append(
    "selected_time_slot_from_datetime",
    addedData.selected_time_slot_from_datetime
  );
  formdata.append(
    "selected_time_slot_to_datetime",
    addedData.selected_time_slot_to_datetime
  );
  try {
    const response = await axios.post(
      config.APIURL + "v1/delivery/calculate-order",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getRazorPayOrderIDAPI(userId, token, amount) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("amount", amount);
  try {
    const response = await axios.post(
      config.APIURL + "v1/razorpay/create-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function updateOrderOnCartChange(data) {
  var formdata = new FormData();
  formdata.append("cart_id", data.cart_id);
  formdata.append("orderjson", JSON.stringify(data));
  try {
    const response = await axios.post(
      config.APIURL + "v1/initiate-order",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function orderDetailAPI(userID, token, detailOrder) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("cart_id", detailOrder.cart_id);
  formdata.append("orderjson", JSON.stringify(detailOrder));
  try {
    const response = await axios.post(
      config.APIURL + "v1/place-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerAddressAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-diner-locations",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function deleteDinerAddressAPI(userID, locationID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("location_id", locationID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/remove-diner-locations",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function updateDinerLocationAPI(data, token) {
  var formdata = new FormData();
  formdata.append("user_id", data.user_id);
  formdata.append("location_id", data.location_id);
  formdata.append("latitude", data.latitude);
  formdata.append("longitude", data.longitude);
  formdata.append("city", data.city);
  formdata.append("location", data.location);
  formdata.append("pincode", data.pincode);
  formdata.append("type", data.type);
  formdata.append("location_title", data.location_title);
  formdata.append("address_line1", data.address_line1);
  formdata.append("address_line2", data.address_line2);
  try {
    const response = await axios.post(
      config.APIURL + "v1/update-diner-locations",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerCurrentOrdersAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  // formdata.append("diner_user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/orders/current",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerPastOrdersAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  // formdata.append("diner_user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/orders/past",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerCancelledOrdersAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  // formdata.append("diner_user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/orders/cancelled",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function cancelDinerOrderAPI(userID, orderID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("order_number", orderID);
  formdata.append("reason_for_cancellation", "N/A");
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/cancel-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefOrderInProgressAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders-in-progress",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefAcceptOrderAPI(userID, orderNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("order_number", orderNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/accept-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefRejectOrderAPI(userID, data, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("order_number", data.order_number);
  formdata.append("reason_for_rejection", data.reason_for_rejection);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/reject-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefDeliveredOrderAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders/delivered",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefCanceledOrderAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders/cancelled",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefOrderSetPreparedAPI(userID, orderNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("order_number", orderNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders/set-order-status-prepared",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function chefOrderSetPickedUpAPI(userID, orderNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  formdata.append("order_number", orderNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders/set-order-status-pickedup",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerInfoAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-diner-profile",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChefRevenueAPI(userID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/orders/revenue",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getEmailOTPAPI(userId, emailID, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-diner-email",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getEmailOTPVerfiyAPI(userId, emailID, otp, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("otp", otp);
  formdata.append("email", emailID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-diner-email-verification",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileOTPAPI(userId, mobileNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("mobile_number", mobileNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-diner-mobile-number",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMobileOTPVerfiyAPI(userId, mobileNumber, otp, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("otp", otp);
  formdata.append("mobile_number", mobileNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-diner-mobile-number-verification",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function changeProfileImage(userId, token, photo, photoname) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("profile_image", photo, photoname);
  try {
    const response = await axios.post(
      config.APIURL + "v1/change-diner-profile-image",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getDinerRefundStatus(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/orders/refund",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getOrderTrackingData(userId, orderNumber, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("order_number", orderNumber);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/orders/track-order",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getFAQData(type) {
  var formdata = new FormData();
  formdata.append("user_type", type);
  try {
    const response = await axios.post(
      config.APIURL + "v1/faq-listing",
      formdata
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getMemberShipData(
  userId,
  chef_name,
  chef_email,
  chef_mobile_number,
  membership_assign,
  token
) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("chef_name", chef_name);
  formdata.append("chef_email", chef_email);
  formdata.append("chef_mobile_number", chef_mobile_number);
  formdata.append("membership_assign", membership_assign);
  try {
    const response = await axios.post(
      config.APIURL + "v1/send-membership-email",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function submitDinerReviewAPI(data, token) {
  var formdata = new FormData();
  formdata.append("user_id", data.user_id);
  formdata.append("diner_id", data.user_id);
  formdata.append("order_number", data.order_number);
  formdata.append("email", data.email);
  formdata.append("food_quality", data.food_quality);
  formdata.append("portion_size", data.portion_size);
  formdata.append("packaging", data.packaging);
  formdata.append("value_for_money", data.value_for_money);
  formdata.append("review", data.review);
  formdata.append("chef_id", data.chef_id);
  formdata.append("order_details", data.order_details);
  formdata.append("order_date", data.order_date);
  try {
    const response = await axios.post(
      config.APIURL + "v1/add-rating-reviews",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function handleChefAvailabilityToggleAPI(userId, isAvailable, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("is_available", isAvailable);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/change-availability",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function handleChefAuditMeToggleAPI(userId, auditMe, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("a_m_a_enabled", auditMe);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/change-audit-me-status",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getBookMarkAPI(userId, token, bookmark, chefID) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("is_bookmark", bookmark);
  formdata.append("chef_id", chefID);
  formdata.append("diner_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/bookmark-chef",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function dinerPinsAchievedAPI(userId, token) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/get-gamification-data",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function deleteBookMarkAPI(userId, token, chefID) {
  var formdata = new FormData();
  formdata.append("user_id", userId);
  formdata.append("chef_id", chefID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/delete-bookmark",
      formdata,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function createOrderNumber(userId, token, data) {
  let formData = new FormData();
  formData.append("user_id", userId);
  formData.append("orderjson", JSON.stringify(data));
  try {
    const response = await axios.post(
      config.APIURL + "v1/create-or-change-order",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function locationCheck(city, selectedLocation) {
  let formData = new FormData();
  formData.append("city", city);
  formData.append("selected_location", selectedLocation);
  try {
    const response = await axios.post(
      config.APIURL + "v1/check-location",
      formData
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getReviewListAPI(chefID) {
  let formData = new FormData();
  formData.append("chef_id", chefID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-reviews",
      formData
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChefReviewAPI(userID, token) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("chef_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/my-reviews",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getCouponCodeAPI(
  userID,
  chefId,
  selectedCity,
  orderAmount,
  cartId,
  token
) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("chef_id", chefId);
  formData.append("order_amount", orderAmount);
  formData.append("city_name", selectedCity);
  formData.append("cart_id", cartId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-available-coupon",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getCouponCodeAppliedAPI(
  userID,
  chefId,
  selectedCity,
  orderAmount,
  couponCode,
  cartId,
  token
) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("chef_id", chefId);
  formData.append("order_amount", orderAmount);
  formData.append("city_name", selectedCity);
  formData.append("coupon_code", couponCode);
  formData.append("cart_id", cartId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/avail-coupon",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getChefReplyUpdateAPI(userID, token, reviewId, chefReply) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("chef_id", userID);
  formData.append("review_id", reviewId);
  formData.append("review_comment", chefReply);
  try {
    const response = await axios.post(
      config.APIURL + "v1/reply-review",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getBlogUploadAPI(
  userID,
  token,
  blogDocument,
  blogName,
  documentTitle
) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("blog_document", blogDocument);
  formData.append("document_title", documentTitle);
  try {
    const response = await axios.post(
      config.APIURL + "v1/upload-blog-document",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getBlogListAPI(userID, token, pageNo) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/get-blog-document-list?page=" + pageNo,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getBookmarkListAPI(userID, token) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/bookmark-listing",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAlertDinerDataCountAPI(userID, token) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/alerts?fetch_record=0",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAlertDinerDataAPI(userID, token, currentPage) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/diner/alerts?fetch_record=1&page=" + currentPage,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAlertChefDataCountAPI(userID, token) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/alerts?fetch_record=0",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function getAlertChefDataAPI(userID, token, currentPage) {
  let formData = new FormData();
  formData.append("user_id", userID);
  try {
    const response = await axios.post(
      config.APIURL + "v1/chef/alerts?fetch_record=1&page=" + currentPage,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

async function removeCouponCodeAPI(userID, token, cartId) {
  let formData = new FormData();
  formData.append("user_id", userID);
  formData.append("cart_id", cartId);
  try {
    const response = await axios.post(
      config.APIURL + "v1/remove-coupon",
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response;
  } catch (error) {
    // you will get error here.
    return error.response;
  }
}

export {
  removeCouponCodeAPI,
  getBookmarkListAPI,
  getBlogUploadAPI,
  getBlogListAPI,
  getCouponCodeAPI,
  getCouponCodeAppliedAPI,
  locationCheck,
  getChefReplyUpdateAPI,
  getChefReviewAPI,
  getReviewListAPI,
  marketingCardsAPI,
  chefListingAPI,
  crowdFavouriteAPI,
  collectionsAPI,
  getLocationListAPI,
  chefBasicListingAPI,
  chefVipListingAPI,
  chefPremiumListingAPI,
  vipHomeChefListingAPI,
  mustTryChefListingAPI,
  popularHomeChefListingAPI,
  newBasicChefListingAPI,
  quickFilterAPI,
  searchDataAPI,
  dishDataAPI,
  chefDataAPI,
  articleTagAPI,
  articleDetailAPI,
  cityAPI,
  crowdFavaoutireAPI,
  bannerAPI,
  getAddressListAPI,
  getChefDetailAPI,
  getChefDataBySlug,
  collectionDetialAPI,
  enquireMessageAPI,
  marketingDetailAPI,
  timeSlotAPI,
  taxCalculationAPI,
  deliveryChargesAPI,
  getRazorPayOrderIDAPI,
  updateOrderOnCartChange,
  orderDetailAPI,
  getDinerAddressAPI,
  deleteDinerAddressAPI,
  updateDinerLocationAPI,
  getDinerCurrentOrdersAPI,
  getDinerPastOrdersAPI,
  getDinerCancelledOrdersAPI,
  cancelDinerOrderAPI,
  chefOrderInProgressAPI,
  chefAcceptOrderAPI,
  chefRejectOrderAPI,
  chefDeliveredOrderAPI,
  chefOrderSetPreparedAPI,
  chefCanceledOrderAPI,
  chefOrderSetPickedUpAPI,
  getDinerInfoAPI,
  getChefRevenueAPI,
  getEmailOTPAPI,
  getEmailOTPVerfiyAPI,
  getMobileOTPAPI,
  getMobileOTPVerfiyAPI,
  changeProfileImage,
  getDinerRefundStatus,
  getOrderTrackingData,
  getFAQData,
  getMemberShipData,
  submitDinerReviewAPI,
  handleChefAvailabilityToggleAPI,
  handleChefAuditMeToggleAPI,
  getBookMarkAPI,
  deleteBookMarkAPI,
  newOnChefListingAPI,
  createOrderNumber,
  dinerPinsAchievedAPI,
  getPlayerIDAPI,
  getAlertDinerDataAPI,
  getAlertChefDataAPI,
  getAlertDinerDataCountAPI,
  getAlertChefDataCountAPI,
};
