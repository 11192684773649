import React from 'react';

export default function DeepLinking({ setRedirect }) {
  // useEffect(() => {
  //   window.location.href = isIOS
  //     ? 'https://apps.apple.com/in/app/chef-pin-home-food-delivery/id1579584069'
  //     : 'https://play.google.com/store/apps/details?id=com.chefpin&hl=en_US&gl=US';
  // }, [redirect]);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <a
        onClick={() => setRedirect(false)}
        style={{ display: 'block', textAlign: 'center', color: 'blue' }}
      >
        Continue on Browser
      </a>
      <a
        href={`chefpin:/${window.location.href.substring(
          window.location.href.split('/', 3).join('/').length
        )}`}
        style={{
          display: 'block',
          textAlign: 'center',
          color: 'blue',
          marginTop: '2rem'
        }}
      >
        Go To App
      </a>
    </div>
  );
}
