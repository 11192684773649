import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
  EditorState,
  convertFromHTML,
  ContentState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import { GetPageDetail } from "../../backendUI/Page/config";
const html = `<p></p>`;

class ArticleData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createWithContent(
        ContentState.createFromText(html)
      ),
    };
    this.state = {
      isLoading: true,
      hideContent: window.matchMedia("(max-width: 991px)").matches,
      articleTag: "",
      articleTitle: "",
      articleContent: "",
    };
  }

  componentDidMount = () => {
    //chefp
    this.getArticleData();

    //quloe
    // this.getArticalDetails();
  };
  getArticalDetails = () => {
    GetPageDetail(this.props.match.params.slug).then((response) => {
      if (response.success == true) {
        var editorData = response.data.description;
        const blocksFromHtml = htmlToDraft(editorData);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({
          articleTag: response.data.name,
          articleTitle: response.data.title,
          editorState: editorState,
          isLoading: false,
        });
        console.log(response.data);
      }
    });
  };

  getArticleData = () => {
    return articleDetailAPI(this.props.match.params.slug)
      .then((response) => {
        if (response.data.status === 200) {
          /* this.setState({ cityArr: response.data.data }); */
          /*   console.log(response.data.data); */
          var editorData = response.data.data.article_content;
          const blocksFromHtml = htmlToDraft(editorData);
          const { contentBlocks, entityMap } = blocksFromHtml;
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({
            articleTag: response.data.data.tag_title,
            articleTitle: response.data.data.article_title,
            editorState: editorState,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        /* console.log(error); */
        this.setState({
          isLoading: false,
        });
      });
  };
  render() {
    console.log(this.state.editorState);
    return (
      <div className="css2">
        <header>
          <div className="container menu_wrapper">
            <div className="logo">
              <a href="/">
                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
              </a>
            </div>
            <div id="nav-icon" className=" d-block d-xl-none">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <ul className="dinner_menu">
              <li className="active">
                {" "}
                <a href="/" className="login_btn">
                  Home
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/diner-aboutus" className="login_btn">
                  About Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a
                  href="https://wa.me/919899622242"
                  target="_blank"
                  className="login_btn"
                >
                  Contact Us
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="/pick-city" className="login_btn">
                  Pick A City
                </a>{" "}
              </li>
              <li className="">
                {" "}
                <a href="https://www.chefp.in/blog/" className="login_btn">
                  Blog
                </a>{" "}
              </li>
              <li className="d-block d-xl-none last_btn">
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <ul className="dinner_menu d-none d-xl-block">
              <li>
                <a className="btn register_btn" href="/chef-homepage">
                  <span className="my_acc_text">Join Us AS a Home Chef</span>
                </a>
              </li>
            </ul>
            <div className="menu_overlay"></div>
          </div>
        </header>
        {this.state.isLoading ? (
          <LoadingDiv />
        ) : (
          <section className="love_cook py-5 article_section">
            <div className="container">
              <span className="text-center">
                <h2 className="text-uppercase color_C07 mb-2 mb-md-3 top_title">
                  {this.state.articleTag}
                </h2>
                <h1 className=" color_a05">{this.state.articleTitle}</h1>
              </span>
              {/* <div
                className="backend_text"
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.articleContent &&
                    this.state.articleContent.replace(
                      /href/g,
                      `target='_blank' href`
                    ),
                }}
              ></div> */}
              <Editor
                toolbarHidden
                editorState={this.state.editorState}
                readOnly
              />
            </div>
            {/* <hr></hr> */}





            <section class="faq_sec diner_faq">
              <div class="container">
                <h1 class="text-center res_back_btn_wrap">
                  FREQUENTLY ASKED QUESTIONS
                </h1>
                <div id="accordion" class="accordion">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h5 class="mb-0">
                        <button
                          class="title"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Do I have to order in advance?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          That is correct. Since Chef Pin is a platform for home
                          chefs, we should all respect that their dishes are not
                          kept ready in bulk, like in the case of restaurants.
                          Advance ordering allows the home chefs to cook fresh
                          food for you.
                        </p>
                        <p>
                          Our system makes sure that all orders are placed at
                          least 1 day prior to delivery. At the time of placing
                          your order you will be able to select your delivery
                          slot for the coming days.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Is there any minimum value to place an order on Chef
                          Pin?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          The minimum value will be displayed for each home chef
                          on their respective pages. You may please refer to
                          that when deciding to order from that home chef.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Are there any delivery charges?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Yes, the delivery charges will be transparently shown
                          to you at the time of placing your order. The delivery
                          amount will be added to your order before payment.
                          Chef Pin uses only the most reputed delivery partners
                          in each city, and the delivery charges will depend on
                          your city and the distance.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can I change the delivery address after the order has
                          been placed?<span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Once the order has been placed we will not be able to
                          accept a request to change the delivery address
                          completely. You might be able to make minor changes
                          (e.g. flat/floor number) by speaking to the delivery
                          executive.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          The package delivered to me has been damaged and/or
                          tampered with. What can I do about this?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          We make sure we use only the most reputed delivery
                          companies, but it is possible that mishaps can happen
                          from time to time. In case the package delivered to
                          you has been damaged and/or tampered with you will be
                          entitled to a partial/full refund. Please see our{" "}
                          <a href="/diner-terms-of-use" class="link">
                            Refund Policy
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          I want to speak to the home chef before placing the
                          order. How can I get their phone number?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          As per policy we do not share the personal contact
                          details of home chefs on the platform. If you have any
                          special requests or customizations you can enter those
                          at the time of placing your order. Otherwise you can
                          write in to{" "}
                          <a href="mailto:info@chefp.in" class="link">
                            info@chefp.in
                          </a>{" "}
                          and we can coordinate with the home chef for you.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          I have some special requests. Is it possible to let
                          the home chef know about these?
                          <span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          Absolutely. One of the main advantages of ordering
                          from home chefs is that you can customize your food.
                          Before you place your order, you will have the option
                          of entering Special Requests which will be
                          communicated to the home chef.
                        </p>
                        <p>
                          But please note that in case your special
                          request/customization cannot be accommodated by the
                          home chef they may have to reject your order.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEight">
                      <h5 class="mb-0">
                        <button
                          class="title collapsed"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          How do I make sure that the food matches my dietary
                          requirements?<span class="plus_icon"></span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordion"
                    >
                      <div class="card-body">
                        <p>
                          At Chef Pin we take dietary requirements very
                          seriously. That is why you will see that under each
                          dish we have asked the home chef to mention the major
                          allergens such as eggs, gluten, lactose etc. We have
                          also asked the home chefs to identify if their
                          kitchens are suitable for certain customs such as
                          Jain-vegetarian cooking. We hope that these indicators
                          and filters will help you find your perfect home chef
                          on Chef Pin!
                        </p>
                        <p>
                          In addition to this, you can also leave a Special
                          Request for the home chef at the time of placing your
                          order so that they are aware of any dietary
                          requirements that you have.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div class="card">
                <div class="card-header" id="headingNine">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      We prefer to order Halal non-veg dishes. Will that be
                      possible?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseNine"
                  class="collapse"
                  aria-labelledby="headingNine"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Yes, you can see which home chefs have marked their meat
                      to be Halal and order from them.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTen">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      How can I change my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapseTen"
                  class="collapse"
                  aria-labelledby="headingTen"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Once an order has been placed, we will consider it
                      confirmed. In case you need to change your order, you
                      would have to cancel the order first and then place a
                      fresh order with the changes you require. Please make sure
                      you read our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading11">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse11"
                      aria-expanded="false"
                      aria-controls="collapse11"
                    >
                      How can I cancel my order?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse11"
                  class="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We provide a 15-minute window in which a diner can cancel
                      their order with no questions asked and get a full refund.
                      If you would like to cancel your order after this free
                      cancellation window, then please read up our{" "}
                      <a href="/diner-terms-of-use" class="link">
                        Cancellation Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading12">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse12"
                      aria-expanded="false"
                      aria-controls="collapse12"
                    >
                      I am not happy with the quality and/or quantity of the
                      food delivered to me. What can I do about this?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse12"
                  class="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      In the unlikely situation that you are not satisfied with
                      your food delivery we request you to first raise the issue
                      with us at{" "}
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We will review your complaint and we shall notify the
                      same to the home chef. For effective complaint resolution
                      we could connect you directly with the home chef, who
                      shall be responsible for redressing your complaint.
                    </p>
                    <p>
                      You may also provide a honest but considerate review on
                      our platform to share your experience.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading13">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse13"
                      aria-expanded="false"
                      aria-controls="collapse13"
                    >
                      How do I provide feedback on my order?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse13"
                  class="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      We have an option to Review and Rate your orders. For this
                      you will need to click on your Past Orders and then select
                      the specific order on which you want to provide the
                      feedback. Please note that we only allow reviews from
                      diners who have actually ordered from the home chef – this
                      is to make sure that each review is genuine.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading14">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse14"
                      aria-expanded="false"
                      aria-controls="collapse14"
                    >
                      I want to place a party order with a home chef. How can I
                      do this?<span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse14"
                  class="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      Please enter a party-order inquiry in the system and we
                      will get the home chef to reply to you.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading15">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse15"
                      aria-expanded="false"
                      aria-controls="collapse15"
                    >
                      What is the Chef Pin customer support number?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse15"
                  class="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You can reach us at +91 98996 22242 or email us at
                      <a href="mailto:info@chefp.in" class="link">
                        info@chefp.in
                      </a>
                      . We also have a WhatsApp chat option on our site which
                      you can use.
                    </p>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading16">
                  <h5 class="mb-0">
                    <button
                      class="title collapsed"
                      data-toggle="collapse"
                      data-target="#collapse16"
                      aria-expanded="false"
                      aria-controls="collapse16"
                    >
                      I am a home chef so how do I register on Chef Pin?
                      <span class="plus_icon"></span>
                    </button>
                  </h5>
                </div>
                <div
                  id="collapse16"
                  class="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <p>
                      You will find a REGISTER AS A HOME CHEF button on our
                      site. Please click on that to register as a home chef on
                      Chef Pin.
                    </p>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </section>
          </section>
        )}
        <div className={this.state.hideContent ? "nofooter" : null}>
          <WhatsAppButton />
        </div>
        {this.state.hideContent ? <DinerMenuTab /> : <DinerFooter />}
      </div>
    );
  }
}

export default ArticleData;
