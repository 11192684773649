/*
#############################################################################
# Name : aboutusDiner.js
# Created by : Arvind Yadav
# Date       : 5th Oct 2020
# Purpose    : This file is used to display the about us data 
#############################################################################
*/
import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { Helmet } from "react-helmet";
// React Notification
import { NotificationManager } from "react-notifications";
import LeaveModal from "../../components/leaveModal";

const numberReg = /^[0]?[789]\d{9}$/;
const emailReg =
  /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)$/;

const formValid = ({ formErrors, ...rest }) => {
  let valid = true;

  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  Object.values(rest).forEach((val) => {
    val === "form-control" && (valid = false);
  });

  return valid;
};

class AboutUsDinerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmail: "",
      userMobile: "",
      userMessage: "",
      provideUserName: "",
      provideUserEmail: "",
      provideUserMobile: "",
      dinnerPopUpStatus: false,
      formErrors: {
        provideUserName: "",
        provideUserEmail: "",
        provideUserMobile: "",
        userName: "",
        userEmail: "",
        userMobile: "",
      },
    };
    this.handleQueryChange = this.handleQueryChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    var userId = "";
    userId = localStorage.getItem("userID");
    // window.addEventListener('beforeunload', this.keepOnPage);
  }

  /*  componentWillUnmount() {
     window.removeEventListener('beforeunload', this.keepOnPage);
   } */

  /* keepOnPage(e) {
    localStorage.clear();
    var message = 'Warning!\n\nNavigating away from this page will delete your text if you haven\'t already saved it.';
    e.returnValue = message;
    return message;
  } */

  /*  handleQueryChange(key) {
     this.setState({
       [key.target.name]: key.target.value,
     });
   }
  */

  /* handleQueryChange function starts here */
  handleQueryChange = (e) => {
    const { name, value } = e.target;
    let formErrors = this.state.formErrors;
    switch (name) {
      case "provideUserName":
        formErrors.provideUserName = value === "" ? "Name is required" : "";
        break;

      case "provideUserEmail":
        formErrors.provideUserEmail =
          value === ""
            ? "Email Id is required"
            : !emailReg.test(e.target.value)
              ? "Enter valid Email Id."
              : "";
        break;

      case "provideUserMobile":
        formErrors.provideUserMobile =
          value === ""
            ? "Mobile Number is required."
            : !numberReg.test(e.target.value)
              ? "Enter valid Mobile Number."
              : "";
        break;

      case "userName":
        formErrors.userName = value === "" ? "Name is required" : "";
        break;

      case "userEmail":
        formErrors.userEmail =
          value === ""
            ? "Email Id is required"
            : !emailReg.test(e.target.value)
              ? "Enter valid Email Id."
              : "";
        break;

      case "userMobile":
        formErrors.userMobile =
          value === ""
            ? "Mobile Number is required."
            : !numberReg.test(e.target.value)
              ? "Enter valid Mobile Number."
              : "";
        break;

      default:
        break;
    }
    this.setState({ formErrors, [name]: value });
  };
  /* handleQueryChange function ends here */

  /* submitContactForm function starts here */
  submitContactForm = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.userName === "") {
      formErrors.userName = "Name is required";
    }

    if (this.state.userMobile === "") {
      formErrors.userMobile = "Mobile Number is required";
    }

    if (this.state.userEmail === "") {
      formErrors.userEmail = "Email Id is required";
    }

    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.recaptcha.execute();
      var formdata = new FormData();
      formdata.append("name", this.state.userName);
      formdata.append("email", this.state.userEmail);
      formdata.append("mobile_number", this.state.userMobile);
      formdata.append("message", this.state.userMessage);
      formdata.append("submission_origin", "frm_have-query");
      axios
        .post(config.APIURL + "v1/send-enquiries-messages", formdata)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Thank you for the query!",
              "Message",
              3000
            );
            this.setState({
              userName: "",
              userEmail: "",
              userMobile: "",
              userMessage: "",
            });
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          NotificationManager.error("Failed to submit the form", "Error!");
        });
    } else {
      this.recaptcha.reset();
    }
  };
  /* submitContactForm function ends here */

  /* submitProvideForm function starts here */
  submitProvideForm = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let formErrors = this.state.formErrors;
    if (this.state.provideUserName === "") {
      formErrors.provideUserName = "Name is required";
    }

    if (this.state.provideUserMobile === "") {
      formErrors.provideUserMobile = "Mobile Number is required";
    }

    if (this.state.provideUserEmail === "") {
      formErrors.provideUserEmail = "Email Id is required";
    }
    this.setState({ formErrors, [name]: value });
    if (formValid(this.state)) {
      this.recaptcha.execute();
      var formdata = new FormData();
      formdata.append("name", this.state.provideUserName);
      formdata.append("email", this.state.provideUserEmail);
      formdata.append("mobile_number", this.state.provideUserMobile);
      formdata.append("submission_origin", "frm_hello-diner");
      axios
        .post(config.APIURL + "v1/send-enquiries-messages", formdata)
        .then((response) => {
          if (response.data["status"] === 200) {
            NotificationManager.success(
              "Thank you for the query!",
              "Message",
              3000
            );
            this.setState({
              provideUserName: "",
              provideUserEmail: "",
              provideUserMobile: "",
              dinnerPopUpStatus: false,
            });
          } else {
            NotificationManager.error(response.data["message"], "Error!");
          }
        })
        .catch((error) => {
          NotificationManager.error("Failed to submit the form", "Error!");
        });
    } else {
      this.recaptcha.reset();
    }
  };
  /* submitProvideForm function ends here */

  /* displayPopup function starts here */
  displayPopup = (event) => {
    event.preventDefault();
    this.setState({
      dinnerPopUpStatus: true,
    });
  };
  /* displayPopup function ends here */

  /* closePopup function starts here */
  closePopup = (event) => {
    event.preventDefault();
    this.setState({
      dinnerPopUpStatus: false,
    });
  };
  /* closePopup function ends here */

  /* onResolved function starts here */
  onResolved() {
    alert("Recaptcha resolved with response: " + this.recaptcha.getResponse());
  }
  /* onResolved function ends here */

  render() {
    const { formErrors } = this.state;
    return (
      <div className="css2 over_hidden">
        {/* <Helmet>
          <title>About US - Chef Pin</title>
            <meta name="title" content="About US - Chef Pin." />
            <meta name="description" content="Love Your Cooking? With Chef Pin you will find a one-stop-shop to discover amazing home-cooked meals that suit your health and your wallet."/>
            <meta name="keywords" content = "home made food services, home food service, home made tiffin service, meal delivery service near me" />
        </Helmet> */}
        <DinnerHeader />
        <section className="love_cook">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-6 d-flex align-items-center">
                <div
                  className="img_wrap desktop_img wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <img
                    src="assets/images/home/love-cooking.png"
                    alt="Love Cooking"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-xl-6 d-flex flex-column align-items-center mobile_padd_none">
                <div className="text_wrap  wow fadeInUp" data-wow-delay="0.5s">
                  <h1 className="text-uppercase color_C07">
                    {/* <a
                      onClick={() => {
                        this.props.removeMobileView()
                        this.props.history.goBack()
                      }}
                      className="res_back_btn mobile_sidebar_back_btn"
                    >
                      <img
                        src="assets/images/icons/left-arrow-black.svg"
                        alt="left-arrow-black"
                      />
                    </a> */}
                    {/* <a
                      onClick={() => {
                        this.props.removeMobileView()
                        this.props.history.goBack()
                      }}
                      className="res_back_btn desktop_sidebar_back_btn"
                    >
                      <img
                        src="assets/images/icons/left-arrow-black.svg"
                        alt="left-arrow-black"
                      />
                    </a> */}
                    LOVE HOME COOKING?
                  </h1>
                  <div className="img_wrap mobile_img">
                    <img
                      src="assets/images/home/love-cooking.png"
                      alt="Love Cooking"
                      className="img-fluid"
                    />
                  </div>
                  <p className="fw_500 bold_para">
                    We truly believe that the best food is homemade, because it
                    is cooked with love, with fresh ingredients and high
                    standards of hygiene. Our home chefs and home bakers cook
                    for the happiness it gives them.
                  </p>
                  <p>
                    With Chef Pin you will find a one-stop-shop to discover
                    amazing home-cooked meals that suit your palate and your
                    wallet.
                  </p>
                  <p className="bold_para">
                    It will be a match made in culinary heaven!
                  </p>
                  <ul
                    id="LoveCookSlider"
                    className="icon_list owl-carousel wow fadeInRight"
                    data-wow-delay="1s"
                  >
                    <li>
                      <span className="icon">
                        <img
                          src="assets/images/icons/cook.png"
                          alt="Cook Icon"
                          className="img-fluid"
                        />
                      </span>
                      <span className="text">
                        Authentic Taste
                        <br />
                        of Home
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <img
                          src="assets/images/icons/ingredients.png"
                          alt="Cook Icon"
                          className="img-fluid"
                        />
                      </span>
                      <span className="text">
                        Fresh and Genuine
                        <br />
                        Ingredients
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <img
                          src="assets/images/icons/trust.png"
                          alt="Cook Icon"
                          className="img-fluid"
                        />
                      </span>
                      <span className="text">
                        Trust in
                        <br />
                        Hygiene
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="ben_reg">
          <div className="container">
            <span className="benifit_leaf_icon"></span>
            <h2
              className="text-uppercase color_C07 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              BENEFITS OF REGISTERING WITH CHEF PIN
            </h2>
            <div className="row">
              <div className="d-none col-xl-4 d-xl-flex align-items-end">
                <div className="img_wrap wow fadeInUp" data-wow-delay="0.7s">
                  <img
                    src="assets/images/home/benefits-of-registering.png"
                    alt="benefits of registering with chef pin"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-xl-8">
                <div className="right_box">
                  <ul
                    id="Benefits_list"
                    className="benefits_list owl-carousel wow fadeInRight"
                    data-wow-delay="1.2s"
                  >
                    <li>
                      <span className="img">
                        <img
                          src="assets/images/icons/recognition.png"
                          alt="Recognition Icon"
                          className="img-fluid"
                        />
                      </span>
                      <h4>recognition</h4>
                      <p>
                        Chef Pin provides a unique platform to make sure that
                        thousands of diners hear your story and sample your
                        cooking. The audience on Chef Pin knows that we are
                        totally focused on promoting home chefs across India.
                      </p>
                    </li>
                    <li>
                      <span className="img">
                        <img
                          src="assets/images/icons/profits.png"
                          alt="Recognition Icon"
                          className="img-fluid"
                        />
                      </span>
                      <h4>PROFITS</h4>
                      <p>
                        Convert your home kitchen into a business. At Chef Pin
                        we know that we will only be successful if we help home
                        chefs make money. So you just concentrate on cooking
                        great food, while we get busy bringing orders to you.
                      </p>
                    </li>
                    <li>
                      <span className="img">
                        <img
                          src="assets/images/icons/convenience.png"
                          alt="Recognition Icon"
                          className="img-fluid"
                        />
                      </span>
                      <h4>CONVENIENCE</h4>
                      <p>
                        Chef Pin has been built to give you full flexibility
                        that allows you to customize your menu, set your prices,
                        choose your delivery options and even select black-out
                        dates when you have other commitments.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="looking_order wow fadeInUp" data-wow-delay="0.5s">
          <div className="container">
            <div className="bg_wrap">
              <h3 className="color_C07 text-uppercase">
                NOT A HOME CHEF, BUT LOOKING TO ORDER?
              </h3>
              <div className="img_wrap">
                <img
                  src="assets/images/home/looking-to-order.png"
                  alt="Looking To Order"
                  className="img-fluid"
                />
              </div>
              <p>
                Thank you for your interest in Chef Pin. Our order-taking system
                is under development. Please leave your details and we will
                contact you as soon as we are open for business.
              </p>
              <a
                href="#"
                className="btn"
                data-toggle="modal"
                data-target="#DinnerModal"
                onClick={this.displayPopup}
              >
                provide details
              </a>
            </div>
          </div> 
    </section>*/}
        <section className="pin_team">
          <div className="container text-center">
            <span className="benifit_leaf_icon"></span>
            <h2 className="color_C07 wow fadeInUp" data-wow-delay="0.5s">
              MEET THE CHEF PIN TEAM
            </h2>
            <p className="wow fadeInUp" data-wow-delay="0.5s">
              What makes Chef Pin special is its core team: a group of
              passionate people who understand the business of food. They have{" "}
              <br />
              worked with various home chefs at the ground level.
            </p>
            <div className="wrapper">
              <ul className="list left wow fadeInLeft" data-wow-delay="1.5s">
                <li>
                  <h4>ROCKY MOHAN</h4>
                  <p>
                    Founder of Gourmet Passport, cookbook author, industrialist
                  </p>
                </li>
                <li>
                  <h4>ABHILASHA CHANDAK</h4>
                  <p>Celebrity chef and Food consultant from Kolkata</p>
                </li>
                <li>
                  <h4>AMEETA AGNIHOTRI</h4>
                  <p>Author and Food Critic based in Chennai</p>
                </li>
                <li>
                  <h4>ASLAM GAFOOR</h4>
                  <p>Hospitality professional based in Bangalore</p>
                </li>
                <li>
                  <h4>ATUL MOHAN</h4>
                  <p>CTO and founder MD Openlogic Systems</p>
                </li>
              </ul>
              <div className="logo_icon wow fadeInUp" data-wow-delay="0.5s">
                <img
                  src="assets/images/icons/logo2.png"
                  alt="Chef Pin Icon"
                  className="img-fluid"
                />
              </div>
              <ul className="list right wow fadeInRight" data-wow-delay="1.5s">
                <li>
                  <h4>PAWAN SONI</h4>
                  <p>Founder of the Indian Food Freak based in Delhi NCR</p>
                </li>
                <li>
                  <h4>SAMEER MALKANI</h4>
                  <p>Co-founder of the FBAI based in Mumbai</p>
                </li>
                <li>
                  <h4>SAI PRIYA</h4>
                  <p>Food Researcher, Celebrated Blogger & Food Stylist</p>
                </li>
                <li>
                  <h4>VIRAJ MOHAN</h4>
                  <p>Home Chef consultant and founder of Kolonel's Kitchen</p>
                </li>
                <li>
                  <h4>SIDDHARTH MOHAN</h4>
                  <p>CEO of Chef Pin, co-founder of Gourmet Passport app</p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/*    <section className="next_step">
          <div className="container text-center">
            <h2 className="color_C07 wow fadeInUp" data-wow-delay="0.5s">
              WHAT ARE THE NEXT STEPS?
            </h2>
            <div className="row justify-content-center">
              <div
                className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp"
                data-wow-delay="1s"
              >
                <div className="box">
                  <div className="img_wrap">
                    <img
                      src="assets/images/home/img-1.png"
                      alt="COMPLETE THE REGISTRATION"
                      className="img-fluid"
                    />
                  </div>
                  <h4>COMPLETE THE REGISTRATION</h4>
                  <p>
                    Registering with Chef Pin is FREE. We will need some details
                    to start creating your listing on the platform. Click on the
                    link and start your journey with Chef Pin.
                    <br />
                    <Link to={"/signup"} className="link">
                      Register Now.
                    </Link>
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp"
                data-wow-delay="1.5s"
              >
                <div className="box">
                  <div className="img_wrap">
                    <img
                      src="assets/images/home/img-2.png"
                      alt="BECOME A MEMBER"
                      className="img-fluid"
                    />
                  </div>
                  <h4>BECOME A MEMBER</h4>
                  <p>
                    After registering, we will contact you to clarify some
                    details. There will be some paperwork and a visit from the
                    Chef Pin team. We will help you understand how it works, and
                    tell you about our free and paid offerings.
                  </p>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4 col-xl-4 wow fadeInUp"
                data-wow-delay="2s"
              >
                <div className="box">
                  <div className="img_wrap">
                    <img
                      src="assets/images/home/img-3.png"
                      alt="START SELLING!"
                      className="img-fluid"
                    />
                  </div>
                  <h4>START SELLING!</h4>
                  <p>
                    Activate your listing on Chef Pin and start getting orders!
                    Our marketing team is making sure that you have a vast
                    audience of eager diners. And our delivery partners are
                    waiting to deliver your home-cooked magic.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="have_query">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-6 col-xl-7 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="contanct_wrap">
                  <h2 className="color_C07 text-uppercase">have a query?</h2>
                  <p>
                    Write to us at{" "}
                    <a href="mailto:info@chefp.in" className="link">
                      info@chefp.in
                    </a>{" "}
                    or fill in the form below. One of our team members will get
                    in <br /> touch with you soon.
                  </p>
                  <div className="form_wrap">
                    <form>
                      <div className="row">
                        <div className="col-md-6 col-lg-12 col-xl-6">
                          <div className="form-group text-left">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name*"
                              name="userName"
                              value={this.state.userName}
                              onChange={this.handleQueryChange}
                              maxLength="48"
                            />
                            <small className="text-danger text-left d-block">
                              {formErrors.userName}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-12 col-xl-6">
                          <div className="form-group text-left">
                            <div className="mobile_no_wrap">
                              <div className="form-control countrycode">
                                <span className="code">+91</span>
                              </div>
                              <input
                                type="tel"
                                className="form-control tel_number"
                                placeholder="Mobile Number*"
                                name="userMobile"
                                value={this.state.userMobile}
                                onChange={this.handleQueryChange}
                                maxLength="10"
                              />
                            </div>
                            <small className="text-danger text-left d-block">
                              {formErrors.userMobile}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="form-group text-left">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email Id*"
                              name="userEmail"
                              value={this.state.userEmail}
                              onChange={this.handleQueryChange}
                            />
                            <small className="text-danger text-left d-block">
                              {formErrors.userEmail}
                            </small>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="form-group text-left">
                            <textarea
                              className="form-control"
                              placeholder="Message"
                              name="userMessage"
                              value={this.state.userMessage}
                              onChange={this.handleQueryChange}
                              maxLength="500"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn"
                              onClick={this.submitContactForm}
                            >
                              submit
                            </button>
                          </div>
                        </div>
                        <Recaptcha
                          ref={(ref) => (this.recaptcha = ref)}
                          sitekey={config.siteKey}
                          onResolved={this.onResolved}
                        />
                      </div>
                    </form>
                  </div>
                  <ul className="social_links">
                    <li>
                      <a
                        href="https://www.facebook.com/Chef-Pin-103131911364130"
                        target="_blank"
                      >
                        <img
                          src="assets/images/icons/facebook-icon.png"
                          alt="Facebook Icon"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/chefp.in/"
                        target="_blank"
                      >
                        <img
                          src="assets/images/icons/instagram-icon.png"
                          alt="Instagram Icon"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/ChefPin1" target="_blank">
                        <img
                          src="assets/images/icons/twitter-icon.png"
                          alt="Twitter Icon"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-5 d-none d-lg-flex align-items-center wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="img_wrap">
                  <img
                    src="assets/images/home/contact-img.png"
                    alt="Contact Image"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.dinnerPopUpStatus ? (
            <div
              className={
                this.state.dinnerPopUpStatus
                  ? "modal fade popup show d-block"
                  : "modal fade popup"
              }
              id="DinnerModal"
              role="dialog"
              aria-labelledby="DinnerModalTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body">
                    <span className="benifit_leaf_icon"></span>
                    <div className="add_another_btn">
                      <button
                        type="button"
                        className="close plus_icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.closePopup}
                      ></button>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                        <h2>HELLO DINER!</h2>
                        <p>
                          Thank you for your interest in Chef Pin. Please leave
                          your details and we will contact you as soon as we are
                          open for business.
                        </p>
                        <div className="form_wrap">
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name*"
                                    name="provideUserName"
                                    value={this.state.provideUserName}
                                    onChange={this.handleQueryChange}
                                    maxLength="48"
                                  />
                                  <small className="text-danger text-left d-block">
                                    {formErrors.provideUserName}
                                  </small>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email Id*"
                                    name="provideUserEmail"
                                    value={this.state.provideUserEmail}
                                    onChange={this.handleQueryChange}
                                  />
                                  <small className="text-danger text-left d-block">
                                    {formErrors.provideUserEmail}
                                  </small>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="mobile_no_wrap">
                                    <div className="form-control countrycode">
                                      <span className="code">+91</span>
                                    </div>
                                    <input
                                      type="tel"
                                      className="form-control tel_number"
                                      placeholder="Mobile Number*"
                                      name="provideUserMobile"
                                      value={this.state.provideUserMobile}
                                      onChange={this.handleQueryChange}
                                      maxLength="10"
                                    />
                                  </div>

                                  <small className="text-danger text-left d-block">
                                    {formErrors.provideUserMobile}
                                  </small>

                                  <small className="form-text text-muted text-left">
                                    Enter your 10 digit mobile number.
                                  </small>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-12 col-xl-12">
                                <div className="form-group mb-0">
                                  <button
                                    className="btn d-none d-md-block"
                                    onClick={this.submitProvideForm}
                                  >
                                    send
                                  </button>
                                  <button
                                    className="btn d-block d-md-none mx-auto"
                                    onClick={this.submitProvideForm}
                                  >
                                    Submit
                                  </button>
                                  <Recaptcha
                                    ref={(ref) => (this.recaptcha = ref)}
                                    sitekey={config.siteKey}
                                    onResolved={this.onResolved}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-xl-7 d-none d-md-flex align-items-center justify-content-end">
                        <div>
                          <img
                            src="/assets/images/home/hello-dinner-pop-img.png"
                            alt="Hello Dinner Image"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.dinnerPopUpStatus ? (
            <div className="modal-backdrop fade show"></div>
          ) : null}
        </section> */}
        <WhatsAppButton />
        <DinerFooter />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  mobileView: state.chefMenu.isActive,
});

export default connect(mapStateToProps, { removeMobileView })(AboutUsDinerPage);
