import React from "react";
import DinnerHeader from "../../components/dinnerHeader";
import DinerFooter from "../../components/dinerFooter";
import WhatsAppButton from "../../components/whatsappButton";
import DinerMenuTab from "../../components/dinerMenuTab";
import LoadingDiv from "../../components/LoadingDiv";
import { connect } from "react-redux";
import { removeMobileView } from "../../redux/actions/chefMenuActions";
import { articleDetailAPI } from "../../core/services/Phase2APIservice";
import {
    EditorState,
    convertFromHTML,
    ContentState,
    convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";
import {
    BlogDetailData,
    BlogData,
    BlogCategoryData,
} from "../../backendUI/Blog/config";
import { getDays, getMonth } from "../../backendUI/Fun";
import { URLs } from "../../backendUI/Url";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TumblrShareButton,
    TwitterShareButton,
} from "react-share";
const html = `<p></p>`;

class HomeCookedFood extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(
                ContentState.createFromText(html)
            ),
        };
        this.state = {
            isLoading: true,
            hideContent: window.matchMedia("(max-width: 991px)").matches,
            articleTag: [],
            articleTitle: "",
            author: "",
            authorImage: "",
            articleContent: "",
            catgory: "",
            time: "",
            categorydata: [],
            data: [],
        };
    }
    // 63ad512db0441bf02241a2c2


    getblogcatdata = () => {
        BlogCategoryData().then((response) => {
            if (response.success == true) {
                this.setState({
                    categorydata: response.data,
                });
            }
        });
    };
    render() {

        return (
            <div className="css2">
                <header>
                    <div className="container menu_wrapper">
                        <div className="logo">
                            <a href="/">
                                <img src="/assets/images/chef-pin.png" alt="Chef Pin" />
                            </a>
                        </div>
                        <div id="nav-icon" className=" d-block d-xl-none">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <ul className="dinner_menu">
                            <li>
                                {" "}
                                <a href="/" className="login_btn">
                                    Home
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/diner-aboutus" className="login_btn">
                                    About Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a
                                    href="https://wa.me/919899622242"
                                    target="_blank"
                                    className="login_btn"
                                >
                                    Contact Us
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/pick-city" className="login_btn">
                                    Pick A City
                                </a>{" "}
                            </li>
                            <li className="">
                                {" "}
                                <a href="/blog" className="login_btn">
                                    Blog
                                </a>{" "}
                            </li>
                            <li className="d-block d-xl-none last_btn">
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <ul className="dinner_menu d-none d-xl-block">
                            <li>
                                <a className="btn register_btn" href="/chef-homepage">
                                    <span className="my_acc_text">Join Us AS a Home Chef</span>
                                </a>
                            </li>
                        </ul>
                        <div className="menu_overlay"></div>
                    </div>
                </header>

                <div className="container pt-5">
                    <div className="row align-self">
                        <div className="col-md-12 m-0 p-0">
                            <div className="container">
                                <h2 style={{ color: '#f07c07', textAlign: 'center' }} className="text-center color_a05"> Home Cooked Food
                                </h2>
                                <span className="text-center mt-2">
                                    <h1 className="color_a05">There is a GREAT Home Chef near you!
                                    </h1>
                                </span>

                                <br></br>

                                <div>
                                    <p>
                                        You must have ordered food online many times. It is easy and convenient to get your favorite food just in time that is delivered to your doorstep. Obviously, this is a quick and easy process perfected by the food delivery apps out there. But regular online food delivery from the restaurants offering the lowest prices can cause some serious health hazards because of the food quality. The pressure to deliver fast and cheaply reduces the focus on food quality and hygiene significantly. Stale ingredients can cause damage to your health. Hence considering the situation, it’s better to look for an alternative.
                                    </p>
                                    <p>
                                        Homemade food is becoming a popular alternative to restaurant food thanks to platforms like Chef Pin. Just search for home chefs near me, and you will find excellent home chefs who are offering homemade food. These dishes are prepared by the home chefs who possess excellent culinary skills. Some of them have been professional chefs too but have taken a break from their career. They cook the food only after you place an order that ensures the food you will get will be fresh. If you are looking for homemade food near you, you must look come to Chef Pin.
                                    </p>
                                    <p>
                                        People are looking to order fresh, hygienic, and delicious homemade food from your nearby home chefs. Now you can also join this trend by logging on to Chef Pin. Here you will find a number of home chefs near you who are cooking excellent cuisines in their home kitchen. You simply have to look for home chefs near me on the Chef Pin website or application and you will get a list of nearby home chefs. The best part about the home chefs is they use fresh ingredients, maintain cleanliness throughout the whole process, and retain the authenticity of the cuisine. We provide ultimate satisfaction to our customers with authentic and fresh food filled with love.
                                    </p>
                                    <p>
                                        Now your favorite cuisine can be prepared and delivered to your place in the most hygienic way coming directly from home kitchens. Chef Pin has some amazing chefs who take the cooking experience to the top level.
                                    </p>
                                    <br></br>
                                    <h4 style={{ color: '#555555', fontSize: '22px' }}>Key Reasons you should select Chef Pin for ordering food:</h4>
                                    <br></br>
                                    <ul>
                                        <li>It offers healthy, fresh and hygienic food from home-based chefs</li>
                                        <li>All the recipes use high quality ingredients and are cooked with love by home chefs</li>
                                        <li>You can order regional specialties as well as international and exotic cuisines</li>
                                        <li>You will get the authentic taste in the dishes as it is eaten at someone’s home</li>
                                        <li>Find customer testimonials, reviews and recommendations</li>
                                        <li>The convenience of ordering online and getting your meal delivered to your doorstep</li>
                                    </ul>
                                    <p>
                                        Chef Pin has become a savior for people looking for home food delivery in Delhi NCR. It is now expanding to Mumbai, Bangalore, Chennai, and Kolkata. It is India’s leading company and the first choice for thousands of Indians looking for home-cooked food delivery from a home chef nearby.
                                    </p>
                                    <p>
                                        Chef Pin operations are planned for Delhi NCR, Mumbai, Bangalore, Chennai, and Kolkata.
                                    </p>
                                    <br></br>
                                    <p>
                                        Download the Chef Pin mobile app <a href="http://onelink.to/cnpsf3" target="_blank" rel="noopener noreferrer">here</a> to order the best home-cooked meals from talented home chefs near you.
                                    </p>
                                </div>
                                <br></br>


                                <hr />



                            </div>
                        </div>

                    </div>
                </div>

                <h2 style={{ color: '#f07c07', textAlign: 'center' }} className="text-center color_a05">HOME CHEFS NEAR ME
                </h2>


                <section>
                    <div className="container pt-5">
                        <div className="row align-self">
                            <div className="col-md-12 m-0 p-0">
                                <div className="container media-block-container">
                                    <div className="row m-0 p-0">
                                        {/* Block 1 */}
                                        <a
                                            href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/bageechi"
                                            target="_blank"
                                            className="col-md-3 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/Near_me/11.jpeg"
                                                        alt="Block 1 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Bageechi</p>
                                                <p className="media-middle-title">North Indian, Biryani
                                                </p>
                                                <p className="media-description">Venu Mathur is known for cooking the best Khade Masala Gosht, Biryani, Shami Kabab, Badam Parsinde, All Biryanis etc.</p>
                                                {/* <p className="media-title">Read More</p> */}
                                            </div>
                                        </a>

                                        {/* Block 2 */}
                                        <a
                                            href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/the-mmasala-box-co"
                                            target="_blank"
                                            className="col-md-3 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/Near_me/2.jpg"
                                                        alt="Block 2 Image"
                                                    />
                                                </div>
                                                <p className="media-title">The MMasala Box Co.
                                                </p>
                                                <p className="media-middle-title">Condiments

                                                </p>
                                                <p className="media-description">Krishna Gupta is known for cooking the best Yellow Mustard Oil, Laal Maas etc.

                                                </p>
                                                {/* <p className="media-title">Read More</p> */}
                                            </div>
                                        </a>

                                        {/* Block 3 */}
                                        <a
                                            href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/bongfiesta67"
                                            target="_blank"
                                            className="col-md-3 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/Near_me/3.jpg"
                                                        alt="Block 3 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Bongfiesta67
                                                </p>
                                                <p className="media-middle-title">Bengali
                                                </p>
                                                <p className="media-description">Sampurna Gupta is known for cooking the best Chicken Cutlet, Boneless Fish Fillet Fry, Boneless Fish Fillet Cutlet, Beetroot Carrot Chop , Mutton Kosha etc.

                                                </p>
                                                {/* <p className="media-title">Read More</p> */}
                                            </div>
                                        </a>

                                        {/* Block 4 */}
                                        <a
                                            href="https://www.chefp.in/delhi-ncr/chef-inner-homepage/shubha-s-cake-walk"
                                            target="_blank"
                                            className="col-md-3 media-block text-center"
                                            style={{ marginBottom: 10 }}
                                        >
                                            <div className="media-content">
                                                <div className="media-image">
                                                    <img
                                                        className="img-fluid rounded"
                                                        src="/assets/images/Near_me/4.jpg"
                                                        alt="Block 4 Image"
                                                    />
                                                </div>
                                                <p className="media-title">Shubha's Cake Walk
                                                </p>
                                                <p className="media-middle-title">Desserts, Bakery
                                                </p>
                                                <p className="media-description">Shubha Krishna is known for cooking the best Cupcakes, Desserts , Macarons etc.

                                                </p>
                                                {/* <p className="media-title">Read More</p> */}
                                            </div>
                                        </a>
                                    </div>
                                    <center> <a href="https://chefp.in/delhi-ncr/must-try-meals" target="_blank"> <p className="media-title">View All</p></a> </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>




                <div className={this.state.hideContent ? "nofooter" : null}>
                    <WhatsAppButton />
                </div>

                <DinerMenuTab />
                <DinerFooter />
            </div>
        );
    }
}

export default HomeCookedFood;
